import {MaybePromise} from '@wspsoft/underscore';
import * as moment from 'moment';
import {CriteriaQueryJson, EntityWriteOptions, Tenant} from '../..';
import {Attachment} from './attachment';
import {User} from './user';

export abstract class KolibriEntity {
  public id?: string;
  public active?: boolean;
  public entityClass?: string;
  public createdOn?: string | moment.Moment;
  public lastModified?: string | moment.Moment;
  public createdBy?: User;
  public createdById?: string;
  public lastModifiedBy?: User;
  public lastModifiedById?: string;
  public icon?: string;
  public color?: string;
  public representativeString?: string;
  public repImageId?: string;
  public repImage?: any;
  public tenant?: Tenant;
  public tenantId?: string;
  public attachments?: Attachment[];

  // arango stuff
  public _rev?: string;
  public _key?: string;
  public _id?: string;

  // api extensions
  public recordOld?: this;
  public dirty?: boolean;
  public enhanced?: boolean;
  public persisted?: boolean;
  public deleted?: boolean;
  public record?: this;
  public localExports?: any;
  public copy?: () => this;
  public clone?: () => this;
  public merge?: (e: KolibriEntity) => void;
  public clearCache?: (relationName?: string) => void;
  public differences?: { field: string; newValue: any; oldValue: any }[];
  public stringify?: (sql?: boolean) => this;
  public parse?: (sql?: boolean) => this;
  public insert?: (options?: EntityWriteOptions) => Promise<this>;
  public update?: (options?: EntityWriteOptions) => Promise<this>;
  public delete?: (options?: EntityWriteOptions, hard?: boolean) => Promise<void>;
  public refresh?: (viewId?: string) => Promise<this>;
  public matches?: (query: CriteriaQueryJson) => MaybePromise<boolean>;
  public changes?: (field: string) => boolean;
  public changesTo?: (field: string, value: any) => boolean;
  public changesFrom?: (field: string, value: any) => boolean;
  public applyTemplate?: (templateName: string) => void;
  public toJSON?: (key?: any) => this;
  public keys?: () => string[];

  // acl stuff
  public _securityInfo?: {
    canUpdate: boolean;
    canREAD: boolean;
    canCREATE: boolean;
    canDELETE: boolean;
    fieldInfo: { [fieldName: string]: string };
  };
}
