<ui-input-wrapper [container]="container" [disable]="disable" [hasLabelAlwaysUp]="true" [helpMessage]="helpMessage" [label]="label"
                  [renderInputGroup]="type === 'form' || type === 'icon-only'" [require]="require">
  <ng-container [ngSwitch]="displayType">
    <ng-container *ngSwitchCase="ChoiceOptionDisplayType.SWITCH">
      <p-selectButton [(ngModel)]="value" [dataKey]="dataKey" [disabled]="disable" [multiple]="multiple" [name]="id" [options]="options" [required]="require"
                      [style]="styleData"
                      styleClass="one-selectbutton one-selectbutton--{{type}} one-selectbutton--size-{{size}} one-selectbutton--{{orientation}} {{disable ?
                  'p-disabled' : ''}}">
        <ng-template let-item>
          <div class="one-selectbutton__button-wrapper" style="--selectButtonColor: {{item.color}}">
            <ui-button (clicked)="$event.cb()" [disable]="disable" [type]="type === 'form' ? '' : type" class="one-selectbutton__button" icon="{{item.icon}}"
                       label="{{item.label}}" styleClass="{{item.value === value ? item.styleClass : ''}}"></ui-button>
            <ui-button (clicked)="$event.cb(); value = undefined;" *ngIf="!disable && !multiple && type==='form' && item.value === value && clearable"
                       [stopPropagate]="true" class="one-selectbutton__button one-selectbutton__button--clear" icon="fas fa-fw fa-times"
                       label="{{'primeng.clear' | translate}}" type="icon-only"></ui-button>
          </div>
        </ng-template>
      </p-selectButton>
    </ng-container>
    <div *ngSwitchDefault class="radio-group">
      <ng-container *ngFor="let option of options">
        <p-radioButton [(ngModel)]="value" [disabled]="disable" [label]="option.label" [name]="id" [style]="styleData" [value]="option.value"></p-radioButton>
      </ng-container>
    </div>
  </ng-container>
</ui-input-wrapper>
