<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [require]="require">
  <div class="one-input{{disable ? ' one-input--readonly' : ''}}">
    <i *ngIf="!multiple && $any(value)?.icon" [className]="'one-autocomplete__column-icon fa-fw ' +$any(value)?.icon" [ngStyle]="{color: value.color}"></i>
    <p-autoComplete #nativeInput (completeMethod)="onComplete($event)" (onClear)="clear()" (onHide)="onHide.emit($event); removeScrollListenerAndReset()"
                    (onSelect)="onSelect.emit($event)" (onShow)="onShow.emit($event); bindScrollListener()" [(ngModel)]="value" [autoHighlight]="true"
                    [completeOnFocus]="true" [disabled]="disable" [dropdown]="dropdown" [forceSelection]="forceSelection" [group]="!!groupBy"
                    [inputStyle]="styleData" [multiple]="multiple" [required]="require" [showClear]="clearable && !multiple" [showEmptyMessage]="true"
                    [size]="size" [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}" [panelStyle]="{
                    '--subGridDisplay': displayFields?.length > 0 ? 'grid' : 'flex',
                    '--subGridColumns': displayFields?.length + 1,
                    }" field="representativeString" name="native">
      <ng-template *ngIf="!!groupBy" let-group pTemplate="group">
        <ui-field-translator [data]="group.items[0]" [field]="groupBy" [translateNull]="true"></ui-field-translator>
      </ng-template>
      <ng-template let-item pTemplate="selectedItem">
        <ng-container *ngTemplateOutlet="selectedItemTemplate; context: {$implicit: item}"></ng-container>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: item}"></ng-container>
      </ng-template>
      <ng-template pTemplate="dropdownicon">
        <span class="fas fa-fw fa-chevron-down"></span>
      </ng-template>
      <ng-template pTemplate="loadingicon">
        <span class="fas fa-spinner fa-spin"></span>
      </ng-template>
      <ng-template pTemplate="clearicon">
        <span class="fas fa-times"></span>
      </ng-template>
      <ng-template pTemplate="removetokenicon">
        <span class="fas fa-times"></span>
      </ng-template>
    </p-autoComplete>
  </div>
</ui-input-wrapper>

<ng-template #itemTemplate let-item>
  <ui-field-translator [data]="item" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="item" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>

<ng-template #selectedItemTemplate let-item>
  <ui-field-translator [data]="item" [showIcon]="true" field="representativeString" typeName="KolibriEntity"
                       [repImageDimension]="getPillHeight"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="item" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>
