import {_, MaybePromise} from '@wspsoft/underscore';
import {VariableJson} from '../../../model/database/json/variable-json';
import {KolibriEntity} from '../../../model/database/kolibri-entity';
import {Attribute, AttributeComputationType} from '../../../model/xml/attribute';
import {Relation} from '../../../model/xml/relation';
import {AttachmentUtil} from '../../../util/attachment-util';
import {Utility} from '../../../util/utility';
import {AbstractRuntimeModelService} from '../../coded/abstract-runtime-model.service';
import {EntityModel} from '../../entities/entity-model';
import {ApiHandler} from './api-handler';
import {VariableHandler} from './variable-handler';

export class LazyLoaderHandler<T extends KolibriEntity> extends ApiHandler<T> {
  public _relationCache: { [relation: string]: any } = {};
  public _currentVariables: { [attribute: string]: VariableHandler<T> } = {};
  public _onWrite: (field: string, value: string, oldValue: string) => any;
  public _onRead: (field: string) => any;

  public static addAttributeWrapper(proto: any, entityMeta: EntityModel): void {
    for (const attribute of entityMeta.allAttributes.filter(x => !x.computed).reverse()) {
      if (attribute.typeId === '535f99a8-3b06-429b-a267-a421f1ee204d') {
        Object.defineProperty(proto, attribute.name, {
          get(): VariableHandler<any> {
            this._currentVariables[attribute.name] ??= new VariableHandler(this, attribute.name);
            this._onRead?.(attribute.name);
            return this._currentVariables[attribute.name];
          },
          set(value: Record<string, VariableJson> | VariableHandler<any>) {
            const oldValue = this.record[attribute.name];
            // create new with variables
            this.record[attribute.name] = value instanceof VariableHandler ? value.toJSON() : value;
            // reset the object to apply changes when required
            this._currentVariables[attribute.name]?.init(this.record[attribute.name]);
            this.record[attribute.name] = this[attribute.name].toJSON();
            this._onWrite?.(attribute.name, this.record[attribute.name], oldValue);
          },
          enumerable: true,
          configurable: true
        });
      } else {
        if (attribute.typeId === AbstractRuntimeModelService.LARGE_JSON_TYPE || attribute.typeId === AbstractRuntimeModelService.LARGE_BINARY_TYPE) {
          this.addLargeDataAttribute(proto, attribute);
        }
        this.addAttribute(proto, attribute);
      }
    }
  }

  public static addLazyLoader(proto: any, entityMeta: EntityModel): void {
    // apply own relations first to allow override of the parent
    for (const relation of entityMeta.allRelations.reverse()) {
      if (!(relation.name in proto)) {
        const isToOne = Utility.isToOneRelation(relation);
        const idField = Utility.parameterizeEntityName(relation.name);
        if (isToOne) {
          Object.defineProperty(proto, idField, {
            get() {
              this._onRead?.(idField);
              return this.record[idField];
            },
            set(v: string) {
              const oldValue = this.record[idField];
              this.writeIdField(idField, v);
              if (!_.isEqual(v, oldValue)) {
                this._onWrite?.(idField, v, oldValue);
              }
            },
            enumerable: true
          });
        }

        Object.defineProperty(proto, relation.name, {
          get() {
            this._onRead?.(relation.name);
            return this.relationGetter(relation.name, isToOne, relation);
          },
          set(v) {
            const oldValue = this._relationCache[relation.name];
            this.relationSetter(relation.name, v, isToOne);
            if (!_.isEqual(v, oldValue)) {
              this._onWrite?.(relation.name, v, oldValue);
            }
          }
        });
        if (relation.includeInJson) {
          Object.defineProperty(proto, relation.name + 'Cached', {
            get() {
              return _.isPromise(this._relationCache[relation.name]) ? null : this._relationCache[relation.name];
            },
            set(value: any) {
              this._relationCache[relation.name] = value;
            },
            enumerable: true,
            configurable: true
          });
        }
      }
    }
  }

  public static addComputedFields(proto: any, entityMeta: EntityModel): void {
    for (const attribute of entityMeta.allAttributes.filter(x => x.computed).reverse()) {
      const fn = function (this: LazyLoaderHandler<any>, data?: any): MaybePromise<any> {
        return this._enhancer.scriptExecutor.runScript(attribute.script,
          {record: this, data, user: this._enhancer.user}, undefined, `Attribute:${attribute.name}:computedValue`).result;
      };

      const definition: PropertyDescriptor = {
        enumerable: attribute.computed === AttributeComputationType.COMPUTED,
        configurable: true
      };

      if (attribute.computed === AttributeComputationType.FUNCTION) {
        definition.value = fn;
      } else {
        definition.get = fn;
        // these are enumerable and potentially set in some functions
        definition.set = function (this: LazyLoaderHandler<any>, x) {
          // computed fields are persisted in db, and merge should be applied
          if (this.record.hasOwnProperty(attribute.name)) {
            this.record[attribute.name] = x;
          }
        };
      }

      Object.defineProperty(proto, attribute.name, definition);
    }
  }

  public static addAttribute(proto: any, {name}: Attribute): void {
    Object.defineProperty(proto, name, {
      get() {
        this._onRead?.(name);
        return this.record[name];
      },
      set(value: any) {
        const oldValue = this.record[name];
        this.record[name] = value;
        if (!_.isEqual(value, oldValue)) {
          this._onWrite?.(name, value, oldValue);
        }
      },
      enumerable: true,
      configurable: true
    });
  }

  private static addLargeDataAttribute(proto: any, {typeId, name}: Attribute): void {
    Object.defineProperty(proto, name + 'Data', {
      get() {
        this._onRead?.(name);
        if (this.record[name] || !this.id) {
          return this.record[name];
        }
        const isJson = typeId === AbstractRuntimeModelService.LARGE_JSON_TYPE;
        return (typeof global === 'undefined' ?
            fetch(`/files/${this.record.entityClass}/${this.id}/${name}`)
              .then(res => res.ok ? (isJson ? res.json() : res.arrayBuffer().then(x => AttachmentUtil.arrayBufferToBase64(x)))
                .then(x => this.record[name] = x) : this.record[name] = null) :
            global.fileService.s3Store.get(global.fileService.blobBucket, `${this.id}/${name}`)
              .then(x => {
                const payload = x.payload;
                // for large json parse as json again
                if (isJson) {
                  return this.record[name] = JSON.parse(Buffer.from(payload, 'base64').toString());
                } else {
                  return this.record[name] = payload;
                }
              })
        )
          .catch((e) => {
            console.error(`Failed to load large data attribute ${name}`, e, this.id);
            return null;
          });
      },
      set(value: any) {
        const oldValue = this.record[name];
        this.record[name] = value;
        if (!_.isEqual(value, oldValue)) {
          this._onWrite?.(name, value, oldValue);
        }
      }
    });
  }

  public clearCache(relationName?: string): void {
    if (relationName) {
      this._relationCache[relationName] = null;
      this.record[relationName + 'Cached'] = null;
    } else {
      for (const relation of this._enhancer.getMeta(this.record).allRelations) {
        this._relationCache[relation.name] = null;
        this.record[relation.name + 'Cached'] = null;
      }
    }
  }

  /**
   * write the id field, and clear loaded relation when id changes
   */
  private writeIdField(p: string, value: any): void {
    if (this.record[p] !== value) {
      this._relationCache[Utility.unparameterizeEntityName(p)] = null;
    }
    this.record[p] = value;
  }

  private relationSetter(p: string, value: any, toOne: boolean): void {
    // for to one relations also set the foreign key id
    if (toOne) {
      this.record[Utility.parameterizeEntityName(p)] = value ? value.id : null;
    }

    // just set the value as cached on for the getter
    this._relationCache[p] = value;
  }

  /**
   * lazy load value for relation
   */
  private relationGetter<E extends KolibriEntity>(p: string, isToOne: boolean, relation: Relation): MaybePromise<E | E[]> {
    const cacheElement = this._relationCache[p];
    if (!cacheElement) {
      // check if it was included in the json
      if (this.record[p + 'Cached']?.id) {
        return this._relationCache[p] = this._enhancer.firstLevelEnhancing(this.record[p + 'Cached']);
      }

      if (isToOne) {
        const id: string = this.record[Utility.parameterizeEntityName(p)];
        if (id) {
          // save the promise to avoid duplicated http call, but later save result to avoid promise at all
          const options = {...this._enhancer.options, descendants: relation.descendants};
          const service = typeof global === 'undefined' ? this._enhancer.entityServiceFactory.getService(relation.targetId, this._enhancer.user, options) :
            this._enhancer.entityServiceFactory.getBackendService(relation.targetId, options);
          return this._relationCache[p] = service.getEntityById(id).then(x => {
            // only remember when we have data, otherwise keep promise to avoid silly calls when field is set with null
            this._relationCache[p] = x === null ? -1 : x;
            return x;
          });
        } else {
          return null;
        }
      } else if (this.record.persisted) {
        // to many loading only works for persisted entities
        return this._relationCache[p] = this._enhancer.entityService(this.record).getEntityRelation(this.record, relation).then(x => {
          // only remember when we have data, otherwise keep promise to avoid silly calls when field is set with null
          this._relationCache[p] = x === null ? -1 : x;
          return x;
        }).catch(reason => {
            console.error(reason, this);
          }
        );
      } else {
        this._relationCache[p] = [];
        return [];
      }
    }
    return cacheElement === -1 ? null : cacheElement;
  }
}
