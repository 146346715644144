<ui-input-wrapper [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [renderInputGroup]="false" [require]="require">
  <p-multiSelect #nativeInput (onChange)="doChange()" (onFilter)="emitOnFilter($event.filter)" (onPanelHide)="onPanelHide.emit(value)"
                 (onPanelShow)="emitOnFilter('')" [(ngModel)]="value" [dataKey]="dataKey" [defaultLabel]="placeholder" [disabled]="disable"
                 [filterBy]="optionLabel" [filter]="true" [maxSelectedLabels]="2" [optionLabel]="optionLabel" [optionValue]="optionValue" [options]="options"
                 [panelStyleClass]="loading ? 'one-multiselect--loading': ''" [placeholder]="placeholder" [required]="require" appendTo="body"
                 class="one-multiselect--table-filter" emptyFilterMessage="{{'AutoComplete.NoResults' | translate}}" [filterPlaceHolder]="placeholder"
                 emptyMessage="{{'AutoComplete.NoResults' | translate}}" selectedItemsLabel="{{'MultiSelect.SelectedLabel' | translate}}">
    <ng-template let-item pTemplate="item">
      <i *ngIf="item.icon" [className]="'one-autocomplete__column-icon fa-fw ' + item.icon" [ngStyle]="{color: item.value.color}"></i>
      <span>{{item[optionLabel]}}</span>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
      <span class="fa fa-fw fa-chevron-down"></span>
    </ng-template>
    <ng-template pTemplate="filtericon">
      <span class="fa fa-search"></span>
    </ng-template>
    <ng-template pTemplate="closeicon">
      <span class="fa fa-times"></span>
    </ng-template>
  </p-multiSelect>
</ui-input-wrapper>
