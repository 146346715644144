import {AfterContentInit, Component, ElementRef, NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LoginMethod, User} from '@wspsoft/frontend-backend-common';
import {MessageService} from 'primeng/api';
import {AuthService, SettingsService} from '../../../../api';
import {FormComponent} from '../../../../ui';

class UserClass extends User {
  public can(operation: string, entity?: string): boolean;
  public can(operation: string[], entity?: string): boolean[];
  public can(operation: string | string[], entity?: string): boolean | boolean[] {
    throw new Error('Method not implemented.');
  }
}

@Component({
  selector: 'login-self-registration',
  templateUrl: './self-registration.component.html',
  styleUrls: ['./self-registration.component.scss', '../form.scss']
})
export class SelfRegistrationComponent extends FormComponent implements AfterContentInit {
  public user: User = new UserClass();

  public constructor(private authService: AuthService, private settingsService: SettingsService, messageService: MessageService, elementRef: ElementRef,
                     translateService: TranslateService, private zone: NgZone) {
    super(messageService, translateService, elementRef);
  }

  public ngAfterContentInit(): void {
    // well there is no real done, so trigger some time later...
    const subscription = this.zone.onStable.subscribe(() => {
      subscription.unsubscribe();
      this.focusFirstEmptyInput();
    });
  }

  public async doSave(): Promise<boolean> {
    try {
      const url = (window as any).postSelfRegistrationUrl as string;
      await this.authService.selfRegistration(this.user);
      await this.authService.login(this.user.username?.trim(), this.user.password, '', LoginMethod.basic);
      window.location.href = url || '/';
      return true;
    } catch (e: any) {
      this.messageService.add({
        severity: 'error',
        detail: this.translateService.instant('SelfRegistration.UsernameTaken'),
        summary: ''
      });
      return false;
    }
  }

}
