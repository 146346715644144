import * as moment from 'moment';


import {KolibriEntity} from './kolibri-entity';

export enum QueueTaskType {
  script = 'script', change = 'change', index = 'index', scheduler = 'scheduler', import = 'import'
}

export enum QueueTaskStatus {
  error = 'error', success = 'success', pending = 'pending', running = 'running'
}

export class QueueTask extends KolibriEntity {
  public type: QueueTaskType;
  public retry?: number;
  public status?: QueueTaskStatus;
  public completedAudit?: boolean;
  public completedActivity?: boolean;
  public completedCascade?: boolean;
  public completedEmail?: boolean;
  public completedNotification?: boolean;
  public priority?: number;
  public payload: any;
  public manualDelete?: boolean;
  public result?: any;
  public error?: string;
  public workerId?: string;
  public accessDate?: string | moment.Moment;
}
