<ui-input-wrapper [disable]="disable" [filled]="filled" [hasCenteredLabel]="buttonLayout === 'vertical'" [hasInlineLabel]="!renderInputGroup"
                  [hasReversedLabelPosition]="buttonLayout === 'vertical'" [helpMessage]="helpMessage" [label]="label + (!renderInputGroup ? ':' : '')"
                  [renderInputGroup]="renderInputGroup" [require]="require">
  <div [ngClass]="{'one-input': renderInputGroup, 'one-input--inline': !renderInputGroup, 'one-input--readonly': renderInputGroup && disable}">
    <ng-container *ngIf="!multiple else chips">
      <span *ngIf="currency" class="one-input__decorator">{{ currency }}</span>
      <p-inputNumber [(ngModel)]="value" [allowEmpty]="allowEmpty" [buttonLayout]="buttonLayout" [disabled]="disable" [locale]="locale"
                     [useGrouping]="showSeparator" [maxFractionDigits]="maxFractionDigits" [max]="max" [minFractionDigits]="minFractionDigits" [min]="min"
                     [size]="1" [ngModelOptions]="{updateOn: updateOn}" [ngStyle]="styleData" [required]="require" [showButtons]="showButtons && !disable"
                     [step]="step" mode="decimal" name="native">
        <ng-template pTemplate="incrementbuttonicon">
          <span class="fa fa-angle-up"></span>
        </ng-template>
        <ng-template pTemplate="decrementbuttonicon">
          <span class="fa fa-angle-down"></span>
        </ng-template>
      </p-inputNumber>
    </ng-container>
  </div>
</ui-input-wrapper>
<ng-template #chips>
  <ui-number-chips [(ngModel)]="value" [addOnBlur]="true" [addOnTab]="true" [allowDuplicate]="false" [buttonLayout]="buttonLayout" [disabled]="disable"
                   [locale]="locale" [maxFractionDigits]="maxFractionDigits" [maxValue]="max" [minFractionDigits]="minFractionDigits" [minValue]="min"
                   [ngModelOptions]="{updateOn: updateOn}" [required]="require" [showButtons]="showButtons && !disable" [step]="step" [style]="styleData"
                   mode="decimal" name="native" separator="."></ui-number-chips>
</ng-template>
