<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [require]="require">
  <p-autoComplete #nativeInput (completeMethod)="onComplete($event)" (onClear)="clear()" (onHide)="removeScrollListenerAndReset()"
                  (onSelect)="onSelect.emit($event)" (onShow)="bindScrollListener()" (onUnselect)="onUnselect.emit($event)" [(ngModel)]="targetValue"
                  [autoHighlight]="true" [completeOnFocus]="true" [disabled]="disable" [dropdown]="dropdown" [forceSelection]="false" [inputStyle]="styleData"
                  [multiple]="multiple" [required]="require" [size]="size" [suggestions]="suggestions" appendTo="body"
                  emptyMessage="{{'AutoComplete.NoResults' | translate}}" [panelStyle]="{
                    '--subGridDisplay': displayFields?.length > 0 ? 'grid' : 'flex',
                    '--subGridColumns': displayFields?.length + 1,
                    }" field="representativeString" name="native" styleClass="one-input{{disable ? ' one-input--readonly' : ''}}">
    <ng-template let-entity pTemplate="selectedItem">
      <div (click)="openRecord(entity, $event)" class="one-helper--pointer">
        <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
      </div>
    </ng-template>
    <ng-template let-entity pTemplate="item">
      <ng-container *ngTemplateOutlet="itemTemplate; context: {$implicit: entity}"></ng-container>
    </ng-template>
    <ng-template pTemplate="dropdownicon">
      <span class="fas fa-fw fa-chevron-down"></span>
    </ng-template>
    <ng-template pTemplate="loadingicon">
      <span class="fas fa-spinner fa-spin"></span>
    </ng-template>
    <ng-template pTemplate="removetokenicon">
      <span class="fas fa-times"></span>
    </ng-template>
  </p-autoComplete>
</ui-input-wrapper>

<ng-template #itemTemplate let-entity>
  <ui-field-translator [data]="entity" [showIcon]="true" field="representativeString" typeName="KolibriEntity"></ui-field-translator>
  <ng-container *ngIf="displayFields">
    <ui-field-translator *ngFor="let field of displayFields" [data]="entity" [field]="field"></ui-field-translator>
  </ng-container>
</ng-template>
