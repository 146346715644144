import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Host, Input, OnDestroy, OnInit, Optional, Output, SkipSelf} from '@angular/core';
import {AbstractControlDirective, ControlContainer, NgForm, NgModelGroup, ValidationErrors} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {CustomValidationMessage} from '@wspsoft/frontend-backend-common';
import linkifyStr from 'linkifyjs/string';
import {Subscription} from 'rxjs';
import {ButtonClickEvent} from '../../../entities/button-entities';

@Component({
  selector: 'ui-input-wrapper',
  templateUrl: './input-wrapper.component.html',
  styleUrls: ['./input-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputWrapperComponent implements OnInit, OnDestroy {
  @Input()
  public require: boolean = false;
  @Input()
  public renderInputGroup: boolean = true;
  @Input()
  public hasInlineLabel: boolean = false;
  @Input()
  public hasReversedLabelPosition: boolean = false;
  @Input()
  public hasCenteredLabel: boolean = false;
  @Input()
  public hasLabelAlwaysUp: boolean = false;
  @Input()
  public filled: boolean = false;
  @Input()
  public label: string;
  @Input()
  public helpMessage: string;
  @Input()
  public disable: boolean;
  @Input()
  public linkify: boolean;
  @Input()
  public linkifyRows: number = 1;
  @Input()
  public editMode: boolean;
  @Input()
  public ngClassLabel: any;
  @Output()
  public editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  public onLabelClicked: EventEmitter<any> = new EventEmitter();
  public hovered: boolean = false;
  public linkifyValue: string;
  public hasFocus: boolean;
  private isLinkified: boolean;
  private modelChangeSubscription: Subscription;

  public constructor(private translate: TranslateService, private cdr: ChangeDetectorRef,
                     @Optional() @Host() @SkipSelf()
                     private pcontainer: ControlContainer) {
  }

  @Input()
  public get container(): ControlContainer {
    return this.pcontainer;
  }

  public set container(value: ControlContainer) {
    this.pcontainer = value;
  }

  public get model(): AbstractControlDirective {
    return this.pcontainer ?? {} as any;
  }

  private perrors: ValidationErrors;

  public get errors(): ValidationErrors {
    if (this.perrors !== undefined) {
      return this.perrors;
    }

    if (this.model instanceof NgModelGroup) {
      const errors = Object.values(this.model.control?.controls ?? {}).map(control => control.errors);
      return this.perrors = errors.reduce((acc, val) => Object.assign(acc, val), {});
    }
    return this.perrors = this.model.errors;
  }

  public get customErrorMessage(): CustomValidationMessage {
    if (this.model instanceof NgModelGroup) {
      return Object.values(this.model.control?.controls ?? {}).map(control => (control as any).customErrorMessage).filter(x => !!x)[0];
    }
    return (this.model as any).customErrorMessage;
  }

  private pvalue: any;

  @Input()
  public get value(): any {
    return this.pvalue;
  }

  public set value(value: any) {
    this.pvalue = value;

    if (this.linkify && typeof value === 'string') {
      const linkifyValue1 = linkifyStr(value);
      this.linkifyValue = linkifyValue1.replace(/\n/g, '<br>');
      this.isLinkified = linkifyValue1 !== value;
    }
  }

  public get isSpecialFormat(): boolean {
    return this.linkify && typeof this.value === 'string' && this.isLinkified;
  }

  public get severity(): string {
    if (this.hasHelpMessage) {
      return 'info';
    }
    if (this.customErrorMessage && !this.hasSpecificError) {
      return this.customErrorMessage.severity;
    }
    return 'error';
  }

  public get showMessage(): boolean {
    return this.hasHelpMessage || this.isDirty && (this.hasAnyError && this.hasSpecificError || !!this.customErrorMessage);
  }

  public get hasHelpMessage(): boolean {
    return !!(this.hasFocus && this.helpMessage);
  }

  public get hasSpecificError(): boolean {
    return this.errors.required || this.errors.minlength || this.errors.maxlength || this.errors.custom;
  }

  public get hasAnyError(): boolean {
    return !this.model.valid && !!this.errors;
  }

  public get isDirty(): boolean {
    return this.model.dirty || this.model.touched;
  }

  public get message(): string {
    if (this.hasHelpMessage) {
      return this.translate.instant(this.helpMessage);
    }
    if (this.showMessage) {
      if (this.errors.required) {
        return this.translate.instant('Form.Required');
      }
      if (this.errors.minlength) {
        return this.translate.instant('Form.MinLength', {requiredLength: this.errors.minlength.requiredLength});
      }
      if (this.errors.maxlength) {
        return this.translate.instant('Form.MaxLength',
          {requiredLength: this.errors.maxlength.requiredLength});
      }
      if (this.errors.custom) {
        return this.translate.instant(this.errors.custom.message);
      }
      if (this.customErrorMessage) {
        return this.translate.instant(this.customErrorMessage.message);
      }
    }
    return '';
  }

  public doLabelClick($event: MouseEvent): void {
    if (!this.disable) {
      this.onLabelClicked.emit($event);
    }
  }

  public ngOnInit(): void {
    if (this.pcontainer) {
      this.modelChangeSubscription = (this.pcontainer.formDirective as NgForm).statusChanges.subscribe(value => {
        if (value !== 'PENDING') {
          this.perrors = undefined;
          this.cdr.detectChanges();
        }
      });
    }
  }


  public ngOnDestroy(): void {
    this.modelChangeSubscription?.unsubscribe();
  }

  public doLabelButtonClick($event: ButtonClickEvent): void {
    this.editModeChange.emit(!this.editMode);
    $event.cb();
    $event.originalEvent.stopPropagation();
  }
}
