import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {KolibriEntity} from './kolibri-entity';
import {NotificationRuleContent} from './notification-rule-content';

export enum NotificationRuleTrigger {
  INSERT = 'insert',
  UPDATE = 'update',
  DELETE = 'delete',
  INCOMING = 'incoming',
  SUBSCRIPTION = 'subscription'
}

export abstract class NotificationRule extends KolibriEntity {
  public trigger?: NotificationRuleTrigger[];
  public name?: string;
  public condition?: CriteriaQueryJson | string;
  public contents?: NotificationRuleContent[];
  public entity?: string;
  public order?: number;

  public actionScript?: any;

  public recipientsScripted?: boolean;
  public recipientsScript?: string;
  public recipientFields?: string[];
}
