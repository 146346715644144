import {CommonModule, NgStyle} from '@angular/common';
import {NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {DynamicModule} from 'ng-dynamic-component';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {ImageCropperModule} from 'ngx-image-cropper';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {JoyrideModule} from 'ngx-joyride';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DragDropModule} from 'primeng/dragdrop';
import {DropdownModule} from 'primeng/dropdown';
import {DialogService} from 'primeng/dynamicdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {GalleriaModule} from 'primeng/galleria';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {ListboxModule} from 'primeng/listbox';
import {MenuModule} from 'primeng/menu';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RippleModule} from 'primeng/ripple';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SliderModule} from 'primeng/slider';
import {SpinnerModule} from 'primeng/spinner';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TableModule} from 'primeng/table';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {ApiModule} from '../api';
import {environment} from '../config/environments/environment';
import {NGX_MONACO_EDITOR_CONFIG} from '../third-party/monaco/config';
import {AngularResizedEventModule} from '../third-party/resize/angular-resized-event.module';
import {ButtonComponent} from './app/components/button/button.component';
import {CardsContentComponent} from './app/components/cards-content/cards-content.component';
import {ConditionOverlayComponent} from './app/components/condition/condition-overlay.component';
import {FieldConverterService} from './app/components/converter/field-converter.service';
import {AutocompleteInputComponent} from './app/components/data/autocomplete/autocomplete-input/autocomplete-input.component';
import {ChoiceAutocompleteInputComponent} from './app/components/data/autocomplete/choice-autocomplete-input/choice-autocomplete-input.component';
import {CustomListAutocompleteInputComponent} from './app/components/data/autocomplete/custom-list-autocomplete-input/custom-list-autocomplete-input.component';
import {EntityAutocompleteInputComponent} from './app/components/data/autocomplete/entity-autocomplete-input/entity-autocomplete-input.component';
import {FieldAutocompleteInputComponent} from './app/components/data/autocomplete/field-autocomplete-input/field-autocomplete-input.component';
import {FieldOverlayComponent} from './app/components/data/autocomplete/field-autocomplete-input/field-overlay/field-overlay.component';
// eslint-disable-next-line max-len
import {
  MultiSelectAutocompleteInputComponent
} from './app/components/data/autocomplete/multi-select-autocomplete-input/multi-select-autocomplete-input.component';
import {CalendarComponent} from './app/components/data/calendar/calendar.component';
import {DateRangeSelectorComponent} from './app/components/data/date-range-selector/date-range-selector.component';
import {DropdownComponent} from './app/components/data/dropdown/dropdown.component';
import {FieldValueSelectorComponent} from './app/components/data/field-value-selector/field-value-selector.component';
import {FileUploadComponent} from './app/components/data/file-upload/file-upload.component';
import {ImageCropperComponent} from './app/components/data/image-cropper/image-cropper.component';
import {InputNumberComponent} from './app/components/data/input-number/input-number.component';
import {InputSwitchComponent} from './app/components/data/input-switch/input-switch.component';
import {InputTextComponent} from './app/components/data/input-text/input-text.component';
import {InputWrapperComponent} from './app/components/data/input-wrapper/input-wrapper.component';
import {LargeTextfieldComponent} from './app/components/data/large-textfield/large-textfield.component';
import {ChoiceMultiSelectInputComponent} from './app/components/data/multiselect/choice-autocomplete-input/choice-multi-select-input.component';
import {EntityMultiSelectInputComponent} from './app/components/data/multiselect/entity-autocomplete-input/entity-multi-select-input.component';
import {MultiSelectInputComponent} from './app/components/data/multiselect/multi-select-input/multi-select-input.component';
import {NumberChipsComponent} from './app/components/data/number-chips/number-chips.component';
import {PasswordInputComponent} from './app/components/data/password-input/password-input.component';
import {QueryBuilderGroupComponent} from './app/components/data/query-builder/query-builder-group/query-builder-group.component';
import {AclSelectorComponent} from './app/components/data/query-builder/query-builder-rule/acl-selector/acl-selector.component';
import {QueryBuilderRuleComponent} from './app/components/data/query-builder/query-builder-rule/query-builder-rule.component';
import {QueryBuilderSortGroupComponent} from './app/components/data/query-builder/query-builder-sort-group/query-builder-sort-group.component';
import {QueryBuilderSortRuleComponent} from './app/components/data/query-builder/query-builder-sort-rule/query-builder-sort-rule.component';
import {QueryBuilderComponent} from './app/components/data/query-builder/query-builder/query-builder.component';
import {SearchInputComponent} from './app/components/data/search-input/search-input.component';
import {SelectButtonComponent} from './app/components/data/select-button/select-button.component';
import {TypedSearchComponent} from './app/components/data/typed-search/typed-search.component';
import {ConfirmDialogComponent} from './app/components/dialog/confirm-dialog/confirm-dialog.component';
import {DialogComponent} from './app/components/dialog/dialog/dialog.component';
import {WizardPageComponent} from './app/components/dialog/wizard-page/wizard-page.component';
import {WizardComponent} from './app/components/dialog/wizard/wizard.component';
import {EntityStepsComponent} from './app/components/entity-steps/entity-steps.component';
import {GalleryComponent} from './app/components/gallery/gallery.component';
import {GuidedTourStepComponent} from './app/components/guided-tour/guided-tour-step/guided-tour-step.component';
import {GuidedTourComponent} from './app/components/guided-tour/guided-tour.component';
import {LoaderComponent} from './app/components/loader/loader.component';
import {PillComponent} from './app/components/pill/pill.component';
import {RepImageComponent} from './app/components/rep-image/rep-image.component';
import {UiSliderComponent} from './app/components/slider-filter/ui-slider.component';
import {SplitButtonComponent} from './app/components/split-button/split-button.component';
import {StepsComponent} from './app/components/steps/steps.component';
import {DataViewComponent} from './app/components/structure/data-view/data-view.component';
import {ColumnPickerComponent} from './app/components/structure/datatable/column-picker/column-picker.component';
import {DatatableExporterComponent} from './app/components/structure/datatable/datatable-export/datatable-exporter.component';
import {DatatableComponent} from './app/components/structure/datatable/datatable/datatable.component';
import {FooterComponent} from './app/components/structure/footer/footer.component';
import {KanbanComponent} from './app/components/structure/kanban/kanban.component';
import {MainStructureComponent} from './app/components/structure/main-structure/main-structure.component';
import {ToastComponent} from './app/components/structure/toast/toast.component';
import {TrayLoggerComponent} from './app/components/structure/tray-logger/tray-logger.component';
import {TreeComponent} from './app/components/structure/tree/tree.component';
import {ChoiceTranslationComponent} from './app/components/translation/choice-translation/choice-translation.component';
import {FieldTranslatorComponent} from './app/components/translation/field-translator/field-translator.component';
import {DeferDirective} from './app/directive/defer.directive';
import {GridResizeDirective} from './app/directive/grid-resize.directive';
import {GrabberDirective, ResizableDirective} from './app/directive/resizable.directive';
import {JoinPipe} from './app/pipes/join.pipe';
import {MassTranslatePipe} from './app/pipes/mass-translate.pipe';
import {MomentPipe} from './app/pipes/moment.pipe';
import {PrettyStringifyPipe} from './app/pipes/prettyStringify.pipe';
import {SafePipe} from './app/pipes/safe.pipe';
import {ButtonService} from './app/service/button.service';
import {DynamicContentService} from './app/service/dynamic-content.service';
import {GuidedTourService} from './app/service/guidedTour.service';
import {HotkeyService} from './app/service/hotkey.service';
import {LayoutConditionService} from './app/service/layout-condition.service';
import {MultiTabSynchronizerService} from './app/service/multi-tab-synchronizer.service';
import {OneConfirmService} from './app/service/one-confirm.service';
import {OneDialogService} from './app/service/one-dialog.service';
import {QueryBuilderService} from './app/service/query-builder.service';
import {RedirectorService} from './app/service/redirector.service';
import {ShareableEntityService} from './app/service/shareableEntity.service';
import {SideBarService} from './app/service/side-bar.service';
import {StructureService} from './app/service/structure.service';

@NgModule({
  imports: [
    FormsModule,
    AutoCompleteModule,
    TranslateModule,
    MessageModule,
    InputTextareaModule,
    CommonModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    TooltipModule,
    DialogModule,
    ConfirmDialogModule,
    RadioButtonModule,
    DragDropModule,
    ApiModule,
    CalendarModule,
    ToastModule,
    PickListModule,
    ContextMenuModule,
    TableModule,
    CheckboxModule,
    TriStateCheckboxModule,
    SelectButtonModule,
    MenuModule,
    MultiSelectModule,
    NgxUiLoaderModule,
    SpinnerModule,
    SplitButtonModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    FileUploadModule,
    InfiniteScrollModule,
    RippleModule,
    LazyLoadImageModule,
    AngularResizedEventModule,
    ToggleButtonModule,
    ImageCropperModule,
    JoyrideModule.forRoot(),
    DynamicModule,
    ChipsModule,
    SliderModule,
    ProgressBarModule,
    TreeModule,
    PaginatorModule,
    GalleriaModule,
    DataViewModule,
    OverlayPanelModule,
    ListboxModule,
    ScrollTopModule
  ],
  declarations: [
    AutocompleteInputComponent,
    ChoiceMultiSelectInputComponent,
    EntityMultiSelectInputComponent,
    InputTextComponent,
    LargeTextfieldComponent,
    PasswordInputComponent,
    SearchInputComponent,
    WizardComponent,
    WizardPageComponent,
    DialogComponent,
    ConfirmDialogComponent,
    QueryBuilderComponent,
    QueryBuilderGroupComponent,
    QueryBuilderSortGroupComponent,
    QueryBuilderRuleComponent,
    CalendarComponent,
    ToastComponent,
    TrayLoggerComponent,
    InputWrapperComponent,
    ChoiceAutocompleteInputComponent,
    EntityAutocompleteInputComponent,
    FieldAutocompleteInputComponent,
    ColumnPickerComponent,
    DatatableComponent,
    ChoiceTranslationComponent,
    MomentPipe,
    SafePipe,
    ButtonComponent,
    MainStructureComponent,
    LoaderComponent,
    InputNumberComponent,
    CustomListAutocompleteInputComponent,
    DatatableExporterComponent,
    StepsComponent,
    FieldValueSelectorComponent,
    InputSwitchComponent,
    ResizableDirective,
    GrabberDirective,
    GridResizeDirective,
    FooterComponent,
    DeferDirective,
    DateRangeSelectorComponent,
    RepImageComponent,
    FileUploadComponent,
    FieldTranslatorComponent,
    DropdownComponent,
    SelectButtonComponent,
    MultiSelectAutocompleteInputComponent,
    ImageCropperComponent,
    PillComponent,
    MultiSelectInputComponent,
    JoinPipe,
    MassTranslatePipe,
    KanbanComponent,
    SplitButtonComponent,
    GuidedTourComponent,
    GuidedTourStepComponent,
    EntityStepsComponent,
    UiSliderComponent,
    GalleryComponent,
    QueryBuilderSortRuleComponent,
    DataViewComponent,
    TreeComponent,
    NumberChipsComponent,
    PrettyStringifyPipe,
    CardsContentComponent,
    FieldOverlayComponent,
    AclSelectorComponent,
    TypedSearchComponent,
    ConditionOverlayComponent
  ],
  exports: [
    AutocompleteInputComponent,
    InputTextComponent,
    ChoiceMultiSelectInputComponent,
    EntityMultiSelectInputComponent,
    LargeTextfieldComponent,
    PasswordInputComponent,
    SearchInputComponent,
    WizardComponent,
    WizardPageComponent,
    DialogComponent,
    ConfirmDialogComponent,
    QueryBuilderComponent,
    CalendarComponent,
    ToastComponent,
    TrayLoggerComponent,
    ChoiceAutocompleteInputComponent,
    EntityAutocompleteInputComponent,
    FieldAutocompleteInputComponent,
    ColumnPickerComponent,
    DatatableComponent,
    MomentPipe,
    InputNumberComponent,
    CustomListAutocompleteInputComponent,
    ButtonComponent,
    MainStructureComponent,
    LoaderComponent,
    InputWrapperComponent,
    StepsComponent,
    FieldValueSelectorComponent,
    InputSwitchComponent,
    ResizableDirective,
    GrabberDirective,
    FooterComponent,
    DeferDirective,
    SafePipe,
    FileUploadComponent,
    RepImageComponent,
    DropdownComponent,
    SelectButtonComponent,
    MultiSelectAutocompleteInputComponent,
    ImageCropperComponent,
    FieldTranslatorComponent,
    PillComponent,
    MultiSelectInputComponent,
    JoinPipe,
    MassTranslatePipe,
    DatatableExporterComponent,
    LazyLoadImageModule,
    KanbanComponent,
    SplitButtonComponent,
    GuidedTourComponent,
    GuidedTourStepComponent,
    EntityStepsComponent,
    TreeComponent,
    GalleryComponent,
    DataViewComponent,
    PrettyStringifyPipe,
    CardsContentComponent,
    GridResizeDirective,
    TypedSearchComponent
  ],
  providers: [
    DialogService,
    OneDialogService,
    QueryBuilderService,
    FieldConverterService,
    OneConfirmService,
    MultiTabSynchronizerService,
    StructureService,
    ButtonService,
    SideBarService,
    RedirectorService,
    DynamicContentService,
    LayoutConditionService,
    GuidedTourService,
    ShareableEntityService,
    HotkeyService,
    {
      provide: NGX_MONACO_EDITOR_CONFIG, useValue: {
        baseUrl: `${environment.serverAdd}/static/platform/assets`
      }
    }
  ]
})
export class UiModule {
  public constructor(@Optional() @SkipSelf() parentModule: UiModule) {
    if (parentModule) {
      throw new Error('UiModule is already loaded. Import in your base DashboardModule only.');
    }
  }
}

/**
 * so you might think:
 * what the fuck is this...
 *
 * angular is not capable of setting new css vars with ng style,
 * so we have to cheat here and add this functionality
 */
// @ts-ignore
const setStyle = NgStyle.prototype._setStyle;
// @ts-ignore
NgStyle.prototype._setStyle = function _setStyle(nameAndUnit: string, value: string | number | null | undefined): void {
  // @ts-ignore
  const ngEl = this._ngEl;
  if (nameAndUnit.startsWith('--')) {
    if (value != null) {
      (ngEl.nativeElement as HTMLElement).style.setProperty(nameAndUnit, value as string);
    } else {
      (ngEl.nativeElement as HTMLElement).style.removeProperty(nameAndUnit);
    }
  } else {
    setStyle.bind(this)(nameAndUnit, value);
  }
};
