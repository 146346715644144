import {DesignTheme} from '../xml/design-theme';
import {Attachment} from './attachment';
import {EmailAccount} from './email-account';
import {KolibriEntity} from './kolibri-entity';

export class PlatformSettings extends KolibriEntity {
  public companyDashboardId?: string;
  public companyDashboard?: Attachment;
  public companyLogoId?: string;
  public companyLogo?: Attachment;
  public loaderLogoId?: string;
  public loaderLogo?: Attachment;
  public defaultTheme?: DesignTheme;
  public defaultLanguage?: string;
  public loginInfoText?: string;
  public multiAuthErrorMessage?: string;
  public lastDatabaseSync?: string[];
  public activityStreamAccount?: EmailAccount;
  public userNotificationAccount?: EmailAccount;
  public userNotificationAccountId?: EmailAccount;
  public initWithIndexes?: boolean;
  public supportMail?: string;
  public modelUpgradeVersion?: string;
}
