<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [renderInputGroup]="renderInputGroup" [require]="require">
  <div class="one-input{{disable ? ' one-input--readonly' : ''}}">
    <i *ngIf="!multiple && $any(value)?.icon" [className]="'one-autocomplete__column-icon fa-fw ' +value.icon" [ngStyle]="{color: value.color}"></i>
    <p-autoComplete #nativeInput (completeMethod)="onComplete($event)" (onClear)="clear()" (onSelect)="onSelect.emit($event)" [completeOnFocus]="true"
                    [autoHighlight]="true" [(ngModel)]="value" [showClear]="clearable && !multiple" [disabled]="disable" [dropdown]="dropdown"
                    [forceSelection]="forceSelection" [inputStyle]="styleData" [multiple]="multiple" [required]="require" [size]="size"
                    [suggestions]="suggestions" appendTo="body" emptyMessage="{{'AutoComplete.NoResults' | translate}}" field="label" name="native">
      <ng-template let-item pTemplate="item">
        <i *ngIf="item.icon" [className]="'one-autocomplete__column-icon fa-fw ' + item.icon" [ngStyle]="{color: item.color}"></i>
        <span>{{item.label}}</span>
      </ng-template>
      <ng-template pTemplate="dropdownicon">
        <span class="fas fa-fw fa-chevron-down"></span>
      </ng-template>
      <ng-template pTemplate="loadingicon">
        <span class="fas fa-spinner fa-spin"></span>
      </ng-template>
      <ng-template pTemplate="clearicon">
        <span class="fas fa-times"></span>
      </ng-template>
      <ng-template pTemplate="removetokenicon">
        <span class="fas fa-times"></span>
      </ng-template>
    </p-autoComplete>
  </div>
</ui-input-wrapper>

