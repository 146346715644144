import {sort} from 'fast-sort';

export type MaybePromise<T> = T | Promise<T>;
export type NestedArray<T> = Array<NestedArray<T> | T>;

export abstract class Index {

  /**
   * escapes the string to be used inside the regex
   */
  public static escapeRegExp(s: string): string {
    return s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  /**
   * Like object assign but ignoring any errors that might occur due to getters
   */
  public static assign<E, F>(target: E, source: F): E & F {
    for (const key of Object.keys(source)) {
      const newValue = source[key];

      if (newValue !== undefined) {
        try {
          target[key] = newValue;
        } catch (e: any) {
          // might throw errors for read only attributes
        }
      }
    }
    return target as E & F;
  }

  /**
   * First character to upper
   */
  public static capitalize(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  /**
   * Creates an array of elements split into groups the length of size
   */
  public static chunk<T>(input: T[], size: number): T[][] {
    const chunks = [];
    for (let i = 0; i < input.length; i += size) {
      if ((i + size) > input.length) {
        chunks.push([...input.slice(i)]);
        break;
      }
      chunks.push([...input.slice(i, i + size)]);
    }
    return chunks;
  }

  /**
   * Creates a really fast shallow clone of value
   */
  public static clone<T>(value: T): T {
    // just return primitive value
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // return date as a new generic date
    if (value instanceof Date) {
      return new (value.constructor as DateConstructor)(value) as any;
    }
    // spread to new array
    if (Array.isArray(value)) {
      return [...value] as any;
    }
    // spread to new object
    return {...value};
  }

  /**
   * Creates a really fast deep clone of value
   */
  public static cloneDeep<T>(value: T): T {
    return _.doCloneDeep(value, []);
  }

  /**
   * Creates a really fast shallow clone of value with all properties defined
   */
  public static cloneProperties<T = Record<string, any>>(value: T, enumerable: boolean = false): Partial<T> {
    return Object.entries(_.getAllProperties(value)).reduce((acc, [key, desc]) => {
      if (enumerable) {
        if (desc.enumerable) {
          acc[key] = value[key];
        }
      } else {
        acc[key] = value[key];
      }

      return acc;
    }, {});
  }

  /**
   * Creates an object composed of keys generated from the results of running each element of collection thru iteratee.
   * The corresponding value of each key is the number of times the key was returned by iteratee.
   * The iteratee is invoked with one argument: (value).
   */
  public static countBy<T extends Record<any, any>>(array: T[] | T, predicate?: ((value: T) => any) | keyof T): { [key: string]: number } {
    if (!Array.isArray(array)) {
      array = Object.values(array);
    }

    if (!predicate) {
      const acc = new Map<any, number>();
      for (const elem of Object.values(array)) {
        const elemRep = elem.toString();
        if (!acc.has(elemRep)) {
          acc.set(elemRep, 1);
        } else {
          acc.set(elemRep, acc.get(elemRep) + 1);
        }
      }

      return Object.fromEntries(acc.entries());
    }

    if (typeof predicate === 'string') {
      const acc = {};
      for (const elem of array) {
        if (acc[elem[predicate]]) {
          acc[elem[predicate]] += 1;
        } else {
          acc[elem[predicate]] = 1;
        }
      }

      return acc;
    }
    if (typeof predicate === 'function') {
      const acc = {};
      for (const elem of array) {
        const key = predicate(elem);
        if (acc[key]) {
          acc[key] += 1;
        } else {
          acc[key] = 1;
        }
      }

      return acc;
    }
  }

  /**
   * First character to lower
   */
  public static decapitalize(s: string): string {
    return s.charAt(0).toLowerCase() + s.slice(1);
  }

  /**
   * Creates a debounced function that delays invoking func until after wait milliseconds
   * have elapsed since the last time the debounced function was invoked
   */
  // eslint-disable-next-line space-before-function-paren
  public static debounce<T extends (...args: any) => any>(func: T, delay: number, leading?: boolean): T {
    let timer;
    let waiting;
    return function (this, ...args) {
      if (waiting === undefined && leading) {
        func.call(this, ...args);
      }
      if (!waiting) {
        waiting = true;
        clearTimeout(timer);
        setTimeout(() => {
          timer = setTimeout(() => func.call(this, ...args), delay);
          waiting = false;
        });
      }
    } as T;
  }

  /**
   * Creates an array of unique array values not included in the other provided arrays
   */
  public static difference<T>(array: T[], ...arrays: T[][]): T[] {
    array ??= [];
    const values = new Set(...arrays);
    const result = [];
    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let i = 0; i < array.length; i++) {
      if (values.has(array[i])) {
        continue;
      }
      result.push(array[i]);
    }
    return result;
  }

  /**
   * This method is like _.difference except that it accepts iteratee which is invoked for each
   * element of array and values to generate the criterion by which uniqueness is computed
   */
  public static differenceBy<T1 extends Record<any, any>, T2 extends Record<any, any>>(array1: T1[] = [], array2: T2[] = [],
                                                                                       iteratee: keyof T1): T1[] {
    const values = new Set(array2.map((x) => x[iteratee]));
    return array1.filter((x) => !values.has(x[iteratee]));
  }

  /**
   * This method is like _.difference except that it accepts comparator which is invoked to compare elements of array to values
   * The order and references of result values are determined by the first array
   */
  public static differenceWith<T1, T2>(array1: T1[] = [], array2: T2[] = [], comparator: (a: T1, b: T2) => boolean): T1[] {
    return array1.reduce((target, a) => {
      if (!array2.some((b) => comparator(a, b))) {
        target.push(a);
      }
      return target;
    }, []);
  }

  /**
   * Checks if predicate returns truthy for all elements of collection
   * Iteration is stopped once predicate returns falsy
   */
  public static every<T>(values: T[] | { [key: string]: T }, predicate: keyof T | Partial<T> | ((x: T) => boolean)): boolean {
    values ??= [];
    if (!Array.isArray(values)) {
      return _.every(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.every((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.every((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    if (typeof predicate === 'function') {
      return values.every(predicate);
    }
  }

  /**
   * Iterates over elements of array, returning an array of all elements predicate returns truthy for
   */
  public static filter<T>(values: T[] | { [key: string]: T } = [], predicate: keyof T | Partial<T> | ((x: T) => boolean)): T[] {
    if (!Array.isArray(values)) {
      return _.filter(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.filter((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.filter((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    if (typeof predicate === 'function') {
      return values.filter(predicate);
    }
  }

  /**
   * Iterates over elements of array, returning the first element predicate returns truthy for
   */
  public static find<T>(values: T[] | { [key: string]: T } = [], predicate: keyof T | Partial<T> | ((x: T) => boolean)): T | undefined {
    if (!Array.isArray(values)) {
      return _.find(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.find((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.find((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    if (typeof predicate === 'function') {
      return values.find(predicate);
    }
  }

  /**
   * Finds the index of the first element predicate returns truthy for.
   */
  public static findIndex<T extends Record<any, any>>(values: T[], predicate: Partial<T>, fromIndex?: number): number;
  public static findIndex<T>(values: T[], predicate: (value: T) => boolean, fromIndex?: number): number;
  public static findIndex<T>(values: T[], predicate: (value: T) => boolean | Partial<T>, fromIndex: number = 0): number {
    let pred;
    if (typeof predicate === 'object') {
      pred = (value: T) => {
        for (const [k, v] of Object.entries(predicate)) {
          if (value[k] !== v) {
            return false;
          }
        }
        return true;
      };
    } else {
      pred = predicate;
    }

    let i = fromIndex;
    while (i < values.length) {
      if (!pred(values[i])) {
        ++i;
        continue;
      }
      return i;
    }
    return -1;
  }

  /**
   * Creates an array of flattened values by running each element in collection
   * through iteratee and concatenating its result to the other mapped values
   */
  public static flatMap<T>(values: T[][]): T[];
  public static flatMap<T, K extends keyof T>(values: T[], predicate?: K): T[K];
  public static flatMap<T, S>(values: T[], predicate?: ((value: T, index: number, collection: T[]) => S[])): S[];
  public static flatMap<T, S, K extends keyof T>(values: T[], predicate?: ((value: T, index: number, collection: T[]) => S[]) | K): S[] | T[K] {
    if (typeof predicate === 'string') {
      const arr = Array(values.length);
      for (let i = 0; i < values.length; i++) {
        arr[i] = values[i][predicate];
      }
      return _.flatMap(arr);
    }
    if (typeof predicate === 'function') {
      return _.flatMap<any, S>(values.map(predicate));
    }
    const arr = [];
    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let i = 0; i < values.length; i++) {
      if (!Array.isArray(values[i])) {
        arr.push(values[i]);
        continue;
      }
      // @ts-ignore
      arr.push(...values[i]);
    }
    return arr;
  }

  /**
   * flatten recursive array structure
   */
  public static flatten<T>(xs: NestedArray<T>): T[] {
    const acc = [];
    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let i = 0; i < xs.length; i++) {
      if (Array.isArray(xs[i])) {
        // @ts-ignore
        acc.push(..._.flatten(xs[i]));
      } else {
        acc.push(xs[i]);
      }
    }

    return acc;
  }

  /**
   * flatten recursive array structure
   */
  public static flattenBy<T extends Partial<Record<K, T[]>>, K extends keyof T>(xs: T[], field: K): T[] {
    const acc = [];
    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let i = 0; i < xs.length; i++) {
      if (Array.isArray(xs[i])) {
        for (let j = 0; j < (xs[i] as unknown as T[]).length; j++) {
          acc.push(xs[i][j]);
        }
      } else {
        acc.push(xs[i]);
      }
      if (xs[i][field]) {
        const flattened = _.flattenBy(xs[i][field], field);
        /* eslint-disable @typescript-eslint/prefer-for-of */
        for (let j = 0; j < flattened.length; j++) {
          acc.push(flattened[j]);
        }
      }
    }
    return acc;
  }

  /**
   * Gets the value at path of object. If the resolved value is undefined, the defaultValue is returned in its place.
   */
  public static get<T extends Record<any, any>, K extends keyof T>(object: T, path: K, defaultValue?: T[K]): T[K];
  public static get<T>(object: T, path: string[] | string, defaultValue?: string[] | string): any;
  public static get<T>(object: T, path: string[] | string, defaultValue: any = undefined): any {
    let pathElements = [];
    if (typeof path === 'string') {
      let buf = '';
      let pos = 0;
      while (pos < path.length) {
        if (_.isAlphaNumeric(path[pos])) {
          buf += path[pos];
          ++pos;
          continue;
        }

        if (path[pos] === '.') {
          if (buf.length > 0) {
            pathElements.push(buf);
            buf = '';
          }
          ++pos;
          continue;
        }

        if (path[pos] === '[') {
          if (buf.length > 0) {
            pathElements.push(buf);
            buf = '';
          }
          ++pos;
          while (pos < path.length && path[pos] !== ']') {
            buf += path[pos];
            ++pos;
          }
          if (buf.length > 0) {
            pathElements.push(buf);
            buf = '';
          }
          ++pos;

        }
      }
      if (buf.length > 0) {
        pathElements.push(buf);
      }
    } else {
      pathElements = path;
    }

    let result = object;
    /* eslint-disable @typescript-eslint/prefer-for-of */
    for (let i = 0; i < pathElements.length; ++i) {
      if (!result) {
        return defaultValue;
      }
      result = result[pathElements[i]];
    }

    return result;
  }

  /**
   * get all properties including inherited
   */
  public static getAllProperties(object: Record<any, any>): { [x: string]: PropertyDescriptor } {
    const prototypeOf = Object.getPrototypeOf(object);

    if (prototypeOf === Object.prototype) {
      return {};
    }

    return {...Object.getOwnPropertyDescriptors(prototypeOf), ..._.getAllProperties(prototypeOf)};
  }

  /**
   * Creates an object composed of keys generated from the results of running each element of collection through iteratee
   * The corresponding value of each key is an array of the elements responsible for generating the key
   */
  public static groupBy<T>(values: T[] = [], predicate: ((value: T) => string) | keyof T): { [key: string]: T[] } {
    if (typeof predicate === 'string') {
      const keys = Array(values.length);
      for (let i = 0; i < values.length; i++) {
        keys[i] = values[i][predicate];
      }

      const acc = {};
      for (let i = 0; i < keys.length; i++) {
        if (acc[keys[i]]) {
          acc[keys[i]].push(values[i]);
        } else {
          acc[keys[i]] = [values[i]];
        }
      }

      return acc;
    }
    if (typeof predicate === 'function') {
      return values.reduce((acc, value) => {
        const key = predicate(value);
        (acc[key] = acc[key] || []).push(value);
        return acc;
      }, {});
    }
  }

  /**
   * Returns everything but the last entry of the array.
   */
  public static initial<T>(values: T[]): T[] {
    return values.slice(0, -1);
  }

  /**
   * Creates an array of unique values that are included in all of the provided arrays
   */
  public static intersection<T>(array: T[] = [], ...arrays: T[][]): T[] {
    const values = new Set(...arrays);
    return array.filter((x) => values.has(x));
  }

  /**
   * This method is like _.intersection except that it accepts iteratee which is invoked for each
   * element of each arrays to generate the criterion by which uniqueness is computed
   */
  public static intersectionBy<T1 extends Record<any, any>, T2 extends Record<any, any>>(array1: T1[] = [], array2: T2[] = [],
                                                                                         iteratee: keyof T1): T1[] {
    const values = new Set(array2.map((x) => x[iteratee]));
    return array1.filter((x) => values.has(x[iteratee]));
  }

  /**
   * This method is like _.intersection except that it accepts comparator which is invoked to compare elements of arrays
   * The order and references of result values are determined by the first array
   */
  public static intersectionWith<T1, T2>(array1: T1[] = [], array2: T2[] = [], comparator: (a: T1, b: T2) => boolean): T1[] {
    return array1.reduce((target, a) => {
      if (array2.some((b) => comparator(a, b))) {
        target.push(a);
      }
      return target;
    }, []);
  }

  /**
   * Checks if value is a buffer.
   */
  public static isBuffer(value?: any): boolean {
    // Buffer is only available in Node (we may be in the browser right now):
    const bufferAvail = typeof window === 'undefined';
    return bufferAvail ? Buffer.isBuffer(value) : false;
  }

  /**
   * Checks if value is null, undefined, empty array, empty object but ignoring empty string und 0
   */
  public static isEmpty(value: any): boolean {
    return this.isNull(value) || (typeof value === 'object' ? Array.isArray(value) ? value.length === 0 : Object.keys(value).length === 0 : false);
  }

  /**
   * Performs a deep comparison between two values to determine if they are equivalent
   */
  public static isEqual(value: any, other: any, customizer?: (value: any, other: any, key: string) => boolean | undefined): boolean {
    if (value === other) {
      return true;
    }

    // begin deep comparison if values are objects
    if (value && other && typeof value === 'object' && typeof other === 'object') {
      if (value.constructor !== other.constructor) {
        return false;
      }

      let i: number;
      let length: number;
      if (Array.isArray(value)) {
        length = value.length;
        if (length !== other.length) {
          return false;
        }
        for (i = length; i-- !== 0;) {
          if (!_.isEqual(value[i], other[i], customizer)) {
            return false;
          }
        }
        return true;
      }

      if (value.constructor === RegExp) {
        return value.source === other.source && value.flags === other.flags;
      }
      if (value.valueOf !== Object.prototype.valueOf) {
        return value.valueOf() === other.valueOf();
      }
      if (value.toString !== Object.prototype.toString) {
        return value.toString() === other.toString();
      }

      // allow custom keys function for magic stuff
      const keys = value.keys?.() ?? Object.keys(value);
      length = keys.length;
      if (!customizer) {
        if (length !== (other.keys?.() ?? Object.keys(other)).length) {
          return false;
        }
        for (i = length; i-- !== 0;) {
          if (!(keys[i] in other)) {
            return false;
          }
        }
      }
      let key: string;
      let result: boolean;
      for (i = length; i-- !== 0;) {
        key = keys[i];
        if (customizer) {
          result = customizer(value[key], other[key], key);
        }
        if (result === undefined) {
          if (key === '_securityInfo') {
            continue;
          }

          if (!_.isEqual(value[key], other[key], customizer)) {
            return false;
          }
        } else if (result === false) {
          return false;
        } else {
          result = undefined;
        }
      }

      return true;
    }

    return value !== value && other !== other;
  }

  /**
   * This method is like _.isEqual except that it accepts customizer which is invoked to compare values
   * If customizer returns undefined comparisons are handled by the method instead
   */
  public static isEqualWith(value: any, other: any, customizer: (value: any, other: any, key: string) => boolean | undefined): boolean {
    return _.isEqual(value, other, customizer);
  }

  /**
   * Checks if value is undefined or null
   */
  public static isNull(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * Checks if value is undefined, null or empty string
   */
  public static isNullOrEmpty(value: any): boolean {
    return value === '' || _.isEmpty(value);
  }

  /**
   * Checks if value is classified as a Number primitive or object.
   */
  public static isNumber(value: any): boolean {
    // value === value is necessary as a safeguard against NaN because NaN is not equal to itself.
    return typeof value === 'number' && value === value;
  }

  /**
   * Checks if value is the language type of Object. (e.g. arrays, functions, objects, regexes, new Number(0), and new String(''))
   */
  public static isObject(value: any): boolean {
    const type = typeof value;
    return value !== null && (type === 'object' || type === 'function');
  }

  /**
   * Checks if value is a plain object, that is, an object created by the Object constructor or one with a [[Prototype]] of null.
   */
  public static isPlainObject(value?: any): boolean {
    if (!value) {
      return false;
    }
    if (typeof value !== 'object') {
      return false;
    }
    if (value.prototype === null) {
      return true;
    }
    return value.constructor ? value.constructor === ({}).constructor : true;
  }

  /**
   * Checks if maybe promise is a promise
   */
  public static isPromise(maybePromise: MaybePromise<any>): boolean {
    return maybePromise && !!maybePromise.then && typeof maybePromise.then === 'function';
  }

  /**
   * Checks if value is classified as a typed array.
   */
  public static isTypedArray(value: any): boolean {
    const typedArrayTypes = [Int8Array, Uint8Array, Uint8ClampedArray, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array];
    for (const f of typedArrayTypes) {
      if (value instanceof f) {
        return true;
      }
    }

    return false;
  }

  /**
   * Get last value of array
   */
  public static last<T>(values: T[]): T {
    return values[values.length - 1];
  }

  /**
   * This method is like _.max except that it accepts iteratee which is invoked for
   * each element in array to generate the criterion by which the value is ranked
   */
  public static maxBy<T extends Record<any, any>>(values: T[] = [], predicate: keyof T | ((x: T) => number)): T | undefined {
    if (typeof predicate === 'string') {
      const max = Math.max(...values.map((x) => x[predicate]));
      return values.find((x) => x[predicate] === max);
    }
    if (typeof predicate === 'function') {
      const max = Math.max(...values.map(predicate));
      return values.find((x) => predicate(x) === max);
    }
  }

  /**
   * Evaluates a maybe promise without forcing a promise
   */
  public static maybeAwait<T>(maybePromise: MaybePromise<T>, fn: (x: T) => void): void {
    if (_.isPromise(maybePromise)) {
      (maybePromise as Promise<T>).then(value => fn(value));
    } else {
      fn(maybePromise as T);
    }
  }

  // FIXME: correct typing!
  /**
   * This method is like _.assign except that it recursively merges own and inherited enumerable string keyed properties
   * of source objects into the destination object.
   * Source properties that resolve to undefined are skipped if a destination value exists.
   * Array and plain object properties are merged recursively. Other objects and value types are overridden by assignment.
   * Source objects are applied from left to right. Subsequent sources overwrite property assignments of previous sources.
   */
  public static merge(target: Record<string, any>, ...sources: Record<string, any>[]): Record<string, any> {
    for (const source of sources) {
      _.mergeDeep(target, source);
    }

    return target;
  }

  /**
   * This method is like _.merge except that it accepts customizer which is invoked to produce the merged values of the destination and source properties.
   * If customizer returns undefined, merging is handled by the method instead. The customizer is invoked with two arguments: targetValue and srcValue
   */
  public static mergeWith<T, S>(target: T, s: S, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2>(target: T, s1: S1, s2: S2, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3>(target: T, s1: S1, s2: S2, s3: S3, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4, S5>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4, S5, S6>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4, S5, S6, S7>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4, S5, S6, S7, S8>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8,
                                                             customizer: (target, src) => any);

  public static mergeWith<T, S1, S2, S3, S4, S5, S6, S7, S8, S9>(target: T, s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9,
                                                                 customizer: (target, src) => any);

  public static mergeWith(...args: any[]): any {
    const target = args[0];
    if (args.length > 1) {
      if (typeof args[args.length - 1] === 'function') {
        const customizer = args[args.length - 1];
        return _.doMergeWith(target, args.slice(1, args.length - 1), customizer);
      }
    }

    return _.merge(target, args);
  }

  /**
   * This method is like _.min except that it accepts iteratee which is invoked for
   * each element in array to generate the criterion by which the value is ranked
   */
  public static minBy<T extends Record<any, any>>(values: T[] = [], predicate: keyof T | ((x: T) => number)): T | undefined {
    if (typeof predicate === 'string') {
      const min = Math.min(...values.map((x) => x[predicate]));
      return values.find((x) => x[predicate] === min);
    }
    if (typeof predicate === 'function') {
      const min = Math.min(...values.map(predicate));
      return values.find((x) => predicate(x) === min);
    }
  }

  /**
   * This method is like _.sortBy except that it allows specifying the sort orders of the iteratees to sort by.
   * If orders is unspecified, all values are sorted in ascending order.
   * This also applies for all missing orders if there's less orders than iteratees.
   * Specify 'asc' for ascending or 'desc' for descending order.
   */
  public static orderBy<T>(values: T[] = [], iteratees: keyof T | NestedArray<keyof T> | ((T) => any), orders?: ('asc' | 'desc')[] | 'asc' | 'desc'): T[] {
    const iterateesList = Array.isArray(iteratees) ? iteratees as NestedArray<string> : [iteratees];
    const ordersList = orders ? Array.isArray(orders) ? orders : [orders] : [];

    const callbacks = Array(iterateesList.length).fill({});
    for (let i = 0; i < iterateesList.length; i++) {
      if (typeof (iterateesList[i]) === 'function') {
        callbacks[i] = iterateesList[i];
      } else {
        callbacks[i] = (v) => v[(iterateesList[i] as string)];
      }
    }

    const opts = Array(iterateesList.length).fill({});
    for (let i = 0; i < iterateesList.length; i++) {
      const opt: { asc: 'asc' | 'desc' } = {asc: undefined};
      if (ordersList[i]) {
        opt[ordersList[i]] = callbacks[i];
      } else {
        opt.asc = callbacks[i];
      }
      opts[i] = opt;
    }

    return sort(values).by(opts);
  }

  /**
   * The opposite of _.pick; this method creates an object composed of the own
   * and inherited enumerable properties of object that are not omitted
   */
  public static omit<T>(value: T, ...paths: any[]): Partial<T> {
    let path;
    value = {...value};
    for (path of paths) {
      delete value[path];
    }
    return value;
  }

  /**
   * Pads string on the left and right sides if it's shorter than length
   * Padding characters are truncated if they can't be evenly divided by length
   */
  public static pad(str: string = '', length: number = 0, chars: string = ' '): string {
    const prePad = Math.floor((length - str.length) / 2) + str.length;
    return str.padStart(prePad, chars).padEnd(length, chars);
  }

  /**
   * Pads string on the right side if it's shorter than length
   * Padding characters are truncated if they exceed length
   */
  public static padEnd(str: string = '', length: number = 0, chars: string = ' '): string {
    return str.padEnd(length, chars);
  }

  /**
   * Like Promise.all but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelDo<T = any, S = any>(values: T[], func: (value: T, index: number) => Promise<S> | S, threads: number = 10): Promise<void> {
    const entries = values.entries();
    const worker = async (): Promise<void> => {
      for (const [index, value] of entries) {
        await func(value, index);
      }
    };
    await Promise.all(
      Array.from({length: Math.min(values.length, threads)}, worker)
    );
  }

  /**
   * Like Promise.all but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelMap<T = any, S = any>(values: T[], func: (value: T, index: number) => Promise<S> | S, threads: number = 10,
                                                    inPlace: boolean = false): Promise<S[]> {
    const results = inPlace ? values : Array(values.length);
    const entries = values.entries();
    const worker = async (): Promise<void> => {
      for (const [index, value] of entries) {
        results[index] = await func(value, index);
      }
    };
    await Promise.all(
      Array.from({length: Math.min(values.length, threads)}, worker)
    );
    return results;
  }

  /**
   * Applies callback function to each element and then flattens the resulting array but executed in chunks, so you can have some async concurrency control
   */
  public static async parallelFlatMap<T = any, S = any>(values: T[], func: (value: T, index: number) => Promise<S> | S, threads: number = 10,
                                                        inPlace: boolean = false): Promise<S> {
    return _.flatten(await _.parallelMap(values, func, threads, inPlace)) as S;
  }

  //       lodash results in an empty object
  /**
   * Creates an object composed of the object properties predicate returns truthy for
   */
  public static pick<T extends Record<string, any>>(value: T, keys: string[], clone: boolean = false): Partial<T> {
    return keys.reduce((target, key) => {
      target[key] = clone ? _.cloneDeep(value[key]) : value[key];
      return target;
    }, {});
  }

  /**
   * Creates an object composed of the object properties predicate returns truthy for. The predicate is invoked with two arguments: (value, key).
   */
  public static pickBy<T extends Record<string, any>, >(value: T, predicate: <X extends keyof T>(value: T[X], key: X) => boolean): Record<string, any> {
    const resultObj = {};

    for (const [k, v] of Object.entries(value)) {
      if (predicate(v, k)) {
        resultObj[k] = v;
      }
    }

    return resultObj;
  }

  /**
   * Removes all provided values from the given array using strict equality for comparisons, i.e. ===
   */
  public static pull<T>(arr: T[], ...removeList: T[]): T[] {
    const removeSet = new Set(removeList);
    return arr.filter((el) => !removeSet.has(el));
  }

  /**
   * Produces a random number between the inclusive lower and upper bounds
   * If only one argument is provided a number between 0 and the given number is returned
   */
  public static random(a: number, b: number = 0, float?: boolean): number {
    const random = (): number => {
      const lower = Math.min(a, b);
      const upper = Math.max(a, b);
      return lower + Math.random() * (upper - lower);
    };
    const randomInt = (): number => {
      const lower = Math.ceil(Math.min(a, b));
      const upper = Math.floor(Math.max(a, b));
      return Math.floor(lower + Math.random() * (upper - lower + 1));
    };
    float = !Number.isInteger(a) || (b && !Number.isInteger(b)) || float;
    return float ? random() : randomInt();
  }

  // FIXME fk: args { 'a': 1, 'b': 2, 'c': 3 }, ['d'] produce {d: undefined} as the output

  /**
   * Removes all elements from array that predicate returns truthy for and returns an array of the removed elements.
   * The predicate is invoked with three arguments: (value, index, array).
   */
  public static remove<T>(values: T[] | undefined, predicate: Record<string, any> | ((value: T, index: number) => boolean)): T[] {
    if (values === undefined) {
      return [];
    }

    let pred: (value: T, index: number) => boolean;
    if (typeof predicate !== 'function') {
      pred = (value: T): boolean => {
        for (const key of Object.keys(predicate)) {
          if (value === undefined) {
            return false;
          }
          if (predicate[key] !== value[key]) {
            return false;
          }
        }
        return true;
      };
    } else {
      pred = predicate as (value: T, index: number) => boolean;
    }

    const removedElements = [];
    for (let i = 0; i < values.length; ++i) {
      if (pred(values[i], i)) {
        removedElements.push(values.splice(i--, 1)[0]);
      }
    }

    return removedElements;

  }

  /**
   * Checks if predicate returns truthy for any element of collection
   * iteration is stopped once predicate returns truthy
   */
  public static some<T>(values: T[] | { [key: string]: T }, predicate: keyof T | Partial<T> | ((x: T) => boolean)): boolean {
    values ??= [];
    if (!Array.isArray(values)) {
      return _.some(Object.values(values), predicate);
    }
    if (typeof predicate === 'string') {
      return values.some((x) => !!x[predicate]);
    }
    if (typeof predicate === 'object') {
      let i;
      let key;
      const keys = Object.keys(predicate);
      const length = keys.length;
      return values.some((x) => {
        for (i = length; i-- !== 0;) {
          key = keys[i];
          if (predicate[key] !== x[key]) {
            return false;
          }
        }
        return true;
      });
    }
    if (typeof predicate === 'function') {
      return values.some(predicate);
    }
  }

  /**
   * Creates an array of elements, sorted in ascending order by the results of running each element in a collection thru each iteratee
   * This method performs a stable sort, that is, it preserves the original sort order of equal elements
   */
  public static sortBy<T, K extends keyof T>(array: T[] = [], predicate?: K | K[] | ((x: T) => any)): T[] {
    return sort(array).asc(predicate);
  }

  /**
   * This method is like _.sortedLastIndex except that it accepts iteratee which is invoked for value and each element of array to compute their sort ranking.
   * The iteratee is invoked with one argument: (value).
   * The input array has to be sorted.
   */
  public static sortedLastIndexBy<T>(array: T[], value: T, iteratee: ((v: T) => number) | keyof T): number {
    if (typeof iteratee === 'string') {
      const propName = iteratee as string;
      iteratee = (v: T) => v[propName];
    }
    if (typeof iteratee === 'function') {
      const valueRank = iteratee(value);
      let l = 0;
      let r = array.length - 1;

      while (l <= r) {
        const m = Math.floor((l + r) / 2);
        const pivotRank = iteratee(array[m]);

        if (valueRank < pivotRank) {
          r = m - 1;
          continue;
        } else if (valueRank > pivotRank) {
          l = m + 1;
          continue;
        }

        let p = m;
        while (array[++p] && iteratee(array[p]) === valueRank) {
        }
        return p;
      }
      return l;
    }
  }

  /**
   * This method is like _.sum except that it accepts iteratee which is invoked
   * for each element in array to generate the value to be summed
   */
  public static sumBy<T extends Record<any, any>>(values: T[], predicate: ((value: T) => number) | keyof T): number {
    if (typeof predicate === 'string') {
      return values.reduce((acc, value) => acc + (value[predicate] ?? 0), 0);
    }
    if (typeof predicate === 'function') {
      return values.reduce((acc, value) => acc + (predicate(value) ?? 0), 0);
    }
  }

  /**
   * Interpolates given data properties and returns a string containing the whole data
   */
  public static template(str: string = '', params: { [key: string]: any } = {}, interpolate: RegExp = /\${([\s\S]+?)}/g): string {
    const placeholders = _.uniq(str.match(interpolate));
    for (const placeholder of placeholders) {
      // split and join at current placeholder position with inner keys
      str = str.split(placeholder).join(placeholder.match(/\w+/g)
        // now do a generic property lookup on params to get correct value
        .reduce((value: any, property: string) => value[property], params)
      );
    }
    return str;
  }

  /**
   * Creates a throttled function that only invokes func at most once per every wait milliseconds
   */
  // eslint-disable-next-line space-before-function-paren
  public static throttle<T extends (...args: any) => any>(func: T, delay: number, leading?: boolean): T {
    let waiting;
    return function (this, ...args) {
      if (waiting === undefined && leading) {
        func.call(this, ...args);
      }
      if (!waiting) {
        waiting = true;
        setTimeout(() => {
          func.call(this, ...args);
          waiting = false;
        }, delay);
      }
    } as T;
  }

  /**
   * An alternative to _.reduce; this method transforms object to a new accumulator object which is the result of running each of
   * its own enumerable string keyed properties thru iteratee, with each invocation potentially mutating the accumulator object.
   * If accumulator is not provided, a new object with the same [[Prototype]] will be used.
   * The iteratee is invoked with four arguments: (accumulator, value, key, object).
   * Iteratee functions may exit iteration early by explicitly returning false.
   */
  public static transform<T, S = any>(object: T, iteratee: (accumulator: S, value: any, key: any, object: T) => boolean | void,
                                      accumulator: S = undefined): S {
    if (Array.isArray(object)) {
      if (!accumulator) {
        // @ts-ignore
        accumulator = [];
      }

      for (let i = 0; i < object.length; ++i) {
        const transformed = iteratee(accumulator, object[i], i, object);
        if (transformed !== undefined && !transformed) {
          return accumulator;
        }
      }
      return accumulator;
    } else {
      if (!accumulator) {
        // @ts-ignore
        accumulator = object.constructor.call();
      }
    }

    const keys = Object.keys(object);
    for (let i = 0; i < keys.length; ++i) {
      const transformed = iteratee(accumulator, object[keys[i]], keys[i], object);
      if (transformed !== undefined && !transformed) {
        return accumulator;
      }
    }

    return accumulator;
  }

  /**
   * Removes leading and trailing whitespace or specified characters from string
   */
  public static trim(str: string = '', chars: string = '\\s'): string {
    return str.replace(new RegExp(`^([${chars}]*)(.*?)([${chars}]*)$`), '$2');
  }

  /**
   * will try to resolve a promise within the given timeout
   * if timeout is hit the returned promise will be rejected, otherwise the value of the given promise is returned
   */
  public static tryPromiseWithTimeout(promise: Promise<any>, timeout: number): Promise<any> {
    const timeoutPromise = new Promise((res, rej) => setTimeout(rej, timeout));
    return Promise.race([promise, timeoutPromise]);
  }

  /**
   * Creates a duplicate-free version of an array
   */
  public static uniq<T>(values: T[]): T[] {
    return [...new Set(values)];
  }

  /**
   * This method is like uniq except that it accepts iteratee
   */
  public static uniqBy<T>(values: T[], iteratee: keyof T): T[] {
    const m = new Map<any, T>();
    for (const v of values) {
      m.set(v[iteratee], v);
    }
    return [...m.values()];
  }

  /**
   * Similar to _.uniq except that it accepts comparator which is invoked to compare elements of array
   * the order of result values is determined by the order they occur in the array
   */
  public static uniqWith<T>(arr: T[], fn: (a: T, b: T) => boolean): T[] {
    return arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);
  }

  /**
   * Creates an array of unique values, in order, from all given arrays
   */
  public static union<T>(...values: T[][]): T[] {
    return _.uniq([].concat(...values));
  }

  /**
   * This method is like _.union except that it accepts iteratee which is invoked for each
   * element of each arrays to generate the criterion by which uniqueness is computed
   */
  public static unionBy<T>(array1: T[], array2: T[], iteratee: keyof T): T[] {
    return _.uniqBy([].concat(array1, array2), iteratee);
  }

  /**
   * This method accepts two arrays, one of property identifiers and one of corresponding values
   */
  public static zipObject<T>(properties: string[], values: T[]): { [key: string]: T } {
    return properties.reduce((accumulator, key, index) => {
      accumulator[key] = values[index];
      return accumulator;
    }, {});
  }

  /**
   * This method is like _.zip except that it accepts iteratee to specify how grouped values should be combined.
   * The iteratee is invoked with the elements of each group: (...group).
   */
  public static zipWith<S, TResult>(s: S, iteratee: (S1, S2) => TResult): TResult[];
  public static zipWith<S1, S2, TResult>(s1: S1, s2: S2, iteratee: (S1, S2) => TResult): TResult[];
  public static zipWith<S1, S2, S3, TResult>(s1: S1, s2: S2, s3: S3, iteratee: (S1, S2, S3) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, iteratee: (S1, S2, S3, S4) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, S5, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, iteratee: (S1, S2, S3, S4, S5) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, S5, S6, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6,
                                                         iteratee: (S1, S2, S3, S4, S5, S6) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, S5, S6, S7, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7,
                                                             iteratee: (S1, S2, S3, S4, S5, S6, S7) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, S5, S6, S7, S8, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8,
                                                                 iteratee: (S1, S2, S3, S4, S5, S6, S7, S8) => TResult): TResult[];
  public static zipWith<S1, S2, S3, S4, S5, S6, S7, S8, S9, TResult>(s1: S1, s2: S2, s3: S3, s4: S4, s5: S5, s6: S6, s7: S7, s8: S8, s9: S9,
                                                                     iteratee: (S1, S2, S3, S4, S5, S6, S7, S8, S9) => TResult): TResult[];
  public static zipWith(...sources: any[]): any[] {
    if (sources.length >= 1) {
      if (typeof sources[sources.length - 1] === 'function') {
        const iteratee = sources[sources.length - 1];
        sources.splice(sources.length - 1, 1);
        return _.doZipWith(sources, iteratee);
      }

      if (!sources[sources.length - 1]) {
        sources.splice(sources.length - 1, 1);
      }
    }
    return _.doZipWith(sources, undefined);
  }

  private static isAlphaNumeric(c): boolean {
    const code = c.charCodeAt(0);
    return !(!(code > 47 && code < 58)
      && !(code > 64 && code < 91)
      && !(code > 96 && code < 123));
  }

  private static doCloneDeep<T = any>(value: T, visitedNodes: Array<object>): T {
    // just return primitive values
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // return date as a new generic date
    if (value instanceof Date) {
      return new (value.constructor as any)(value);
    }
    // in case value is an object with a clone method
    if (typeof (value as any).clone === 'function') {
      return (value as any).clone();
    }
    let i: any;
    let newValue: any;
    // iterate recursive over array
    if (Array.isArray(value)) {
      const length = value.length;
      newValue = Array(length);
      for (i = length; i-- !== 0;) {
        if (visitedNodes.includes(value[i])) {
          newValue[i] = value[i];
          continue;
        }
        visitedNodes.push(value[i]);
        newValue[i] = _.doCloneDeep(value[i], visitedNodes);
      }
      return newValue;
    }
    // iterate recursive over generic object
    newValue = Object.create(Object.getPrototypeOf(value));
    for (i in value) {
      if (visitedNodes.includes(value[i])) {
        newValue[i] = value[i];
        continue;
      }
      visitedNodes.push(value[i]);
      newValue[i] = _.doCloneDeep(value[i], visitedNodes);
    }
    return newValue;
  }

  // FIXME: correct typing!
  private static doMergeWith<T extends Record<string, any>, S extends Record<string, any>>(
    target: T,
    sources: S[],
    customizer: (target: T, source: S) => T & S): T & S {
    for (const source of sources) {
      _.mergeWithDeep(target, source, customizer);
    }

    // @ts-ignore
    return target;
  }

  /*
 * This is an alternative, iterative way to achieve the same goal.
 * We should keep it in case the recursion limit (call stack) is exceeded.
 *
 * private static mergeDeepIterative(target: Record<string, any>, source: Record<string, any>): void {
 *   const stack: {src: any, tgt: any, lastVisitedIdx: number}[] = [];
 *   let currSrcObj = source;
 *   let currTgtObj = target;
 *   let lastIdx = 0;
 *
 *   loop:
 *   while (currSrcObj !== undefined) {
 *     const sourceKeys = Object.keys(currSrcObj);
 *
 *     for (let i = lastIdx; i < sourceKeys.length; ++i) {
 *       const key = sourceKeys[i];
 *       if (currTgtObj[key]
 *         && ((_.isPlainObject(currTgtObj[key]) && _.isPlainObject(currSrcObj[key])) || (Array.isArray(currTgtObj[key]) && Array.isArray(currSrcObj[key])))
 *         && !_.isBuffer(currSrcObj[key]) && !_.isTypedArray(currSrcObj[key]))
 *       {
 *         stack.push({src: currSrcObj, tgt: currTgtObj, lastVisitedIdx: i});
 *         stack.push({src: currSrcObj[key], tgt: currTgtObj[key], lastVisitedIdx: -1}); // prob. need lastSrcIdx and lastTgtIdx
 *         currSrcObj = currSrcObj[key];
 *         currTgtObj = currTgtObj[key];
 *         continue loop;
 *       } else {
 *         currTgtObj[key] = currSrcObj[key];
 *       }
 *     }
 *
 *     const entry = stack.pop();
 *     if (!entry) { return; }
 *     currSrcObj = entry.src;
 *     currTgtObj = entry.tgt;
 *     lastIdx = entry.lastVisitedIdx + 1;
 *   }
 * }
 *
 */

  // FIXME: correct typing!
  private static mergeDeep(target: Record<string, any>, source: Record<string, any>): void {
    if (target === source) {
      return;
    }

    const sourceKeys = Object.keys(source);
    for (const key of sourceKeys) {
      if (target[key]
        && ((_.isPlainObject(target[key]) && _.isPlainObject(source[key])) || (Array.isArray(target[key]) && Array.isArray(source[key])))
        && !_.isBuffer(source[key]) && !_.isTypedArray(source[key])) {
        target[key] = _.clone(target[key]);
        _.mergeDeep(target[key], source[key]);
      } else {
        if (source[key] === undefined) {
          continue;
        }
        target[key] = source[key];
      }
    }
  }

  private static mergeWithDeep(target: Record<string, any>, source: Record<string, any>, customizer: (target: any, source: any) => any): void {
    const sourceKeys = Object.keys(source);

    for (const key of sourceKeys) {
      const customized = customizer(target[key], source[key]);
      if (customized) {
        target[key] = customized;
        continue;
      }

      if (target[key] && _.isObject(target[key]) && _.isObject(source[key])) {
        _.mergeWithDeep(target[key], source[key], customizer);
      } else {
        target[key] = source[key];
      }
    }
  }

  private static doZipWith(sources: any[], iteratee: (...args) => any): any[] {
    const maxRows = _.maxBy(sources, (src) => src.length).length;
    const result = Array(maxRows);

    if (!iteratee) {
      for (let i = 0; i < maxRows; ++i) {
        result[i] = Array(sources.length);
        for (let j = 0; j < sources.length; ++j) {
          result[i][j] = sources[j][i];
        }
      }

      return result;
    } else {
      for (let i = 0; i < maxRows; ++i) {
        const args = Array(sources.length);
        for (let j = 0; j < sources.length; ++j) {
          args[j] = sources[j][i];
        }
        result[i] = iteratee(...args);
      }

      return result;
    }
  }
}
export const _ = Index;
