import {EntityModel} from '../../service/entities/entity-model';
import {Field} from '../response/field';
import {DesignerEntity} from './designer.entity';
import {Entity} from './entity';

export abstract class ViewToField extends DesignerEntity {
  public view?: View;
  public viewId?: string;
  public field: Field;
  public fieldId?: string;
  public sortOrder?: string;
  public displayField?: boolean;
}

export abstract class ViewToEntity extends DesignerEntity {
  public view?: View;
  public viewId?: string;
  public entity?: Entity;
  public entityId?: string;
}

export abstract class View extends DesignerEntity {
  public viewToEntities?: ViewToEntity[];
  public viewToFields?: ViewToField[];
  public descendantEntity?: EntityModel;
}
