<ui-input-wrapper [(editMode)]="editMode" [disable]="disable" [filled]="nativeInput.filled" [helpMessage]="helpMessage" [label]="label" [linkify]="linkify"
                  [renderInputGroup]="renderInputGroup" [require]="require">
  <div class="one-input{{disable ? ' one-input--readonly' : ''}}">
    <i *ngIf="!multiple && value?.icon" [className]="'one-autocomplete__column-icon fa-fw ' +value.icon" [ngStyle]="{color: value.color}"></i>
    <p-autoComplete #nativeInput (completeMethod)="onComplete($event)" (onClear)="clear()" (onSelect)="onSelect.emit($event);"
                    (onUnselect)="onUnselect.emit($event)" [(ngModel)]="value" [autoHighlight]="true" [completeOnFocus]="true"
                    [showClear]="clearable && !multiple" [disabled]="disable" [dropdown]="dropdown" [field]="field" [forceSelection]="forceSelection"
                    [minLength]="0" [multiple]="multiple" [required]="require" [size]="size" [suggestions]="suggestions" appendTo="body"
                    emptyMessage="{{'AutoComplete.NoResults' | translate}}" name="native">
      <ng-template let-item pTemplate="item">
        <i *ngIf="item.icon" [className]="'one-autocomplete__column-icon fa-fw ' + item.icon" [ngStyle]="{color: item.color}"></i>
        <span>{{getLabel(item)}}</span>
      </ng-template>
      <ng-template pTemplate="dropdownicon">
        <span class="fas fa-fw fa-chevron-down"></span>
      </ng-template>
      <ng-template pTemplate="loadingicon">
        <span class="fas fa-spinner fa-spin"></span>
      </ng-template>
      <ng-template pTemplate="clearicon">
        <span class="fas fa-times"></span>
      </ng-template>
      <ng-template pTemplate="removetokenicon">
        <span class="fas fa-times"></span>
      </ng-template>
    </p-autoComplete>
  </div>
</ui-input-wrapper>

