import {I18n} from './i18n';
import {KolibriEntity} from './kolibri-entity';
import {ShareableEntityToUserGroup} from './shareable-entity-to-user-group';
import {User} from './user';

export enum Visibility {
  PUBLIC = 'public',
  USER_GROUPS = 'user_groups',
  PRIVATE = 'private'
}

export abstract class ShareableEntity extends KolibriEntity {
  public scope?: string;
  public visibility?: Visibility;
  public name?: I18n;
  public ownerId?: string;
  public owner?: User;
  public shareableEntityToUserGroups?: ShareableEntityToUserGroup[];
}
