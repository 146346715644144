import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, Optional} from '@angular/core';
import {
  AbstractGitService,
  BranchDeleteSummary,
  BranchSummary,
  CommitSummary,
  DiffResult,
  FetchResult,
  PullResult,
  PushResult,
  ResetMode,
  StashList,
  StatusResult,
  Workspace
} from '@wspsoft/frontend-backend-common';
import {Configuration} from '../../configuration';
import {BASE_PATH} from '../../variables';
import {AbstractService} from '../abstract-service';

@Injectable()
export class GitService extends AbstractService implements AbstractGitService {

  public constructor(httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string,
                     @Optional() configuration: Configuration) {
    super(httpClient, basePath, configuration);

    this.basePath = '/api/rest/private/designer/workspaces';
  }

  public clone(workspace: Workspace): Promise<void>;
  public clone(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/clone`, {}, queryParameters, headers);
  }

  public init(workspace: Workspace): Promise<void>;
  public init(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/init`, {}, queryParameters, headers);
  }

  public checkForGitFolder(workspace: Workspace): Promise<boolean>;
  public checkForGitFolder(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<boolean>(`${this.basePath}/${workspace.id}/checkForGitFolder`, queryParameters, headers);
  }

  public checkoutBranch(branch: string, workspace: Workspace): Promise<void>;
  public checkoutBranch(branch: string, workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/branch/${branch}`, {}, queryParameters, headers);
  }

  /**
   * @see AbstractGitService.deleteBranch
   */
  public deleteBranch(branch: string, workspace: Workspace, newBranch: string, force: boolean): Promise<BranchDeleteSummary>;
  public deleteBranch(branch: string, workspace: Workspace, newBranch: string, force: boolean): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('newBranch', newBranch);
    queryParameters = queryParameters.append('force', force.toString());

    return this.doPost<BranchDeleteSummary>(`${this.basePath}/${workspace.id}/branch/delete/${branch}`, {}, queryParameters, headers);
  }

  public newLocalBranch(branch: string, workspace: Workspace): Promise<void>;
  public newLocalBranch(branch: string, workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/newBranch/${branch}`, {}, queryParameters, headers);
  }

  public commit(commitMessage: string, files: string[], workspace: Workspace): Promise<CommitSummary>;
  public commit(commitMessage: string, files: string[], workspace: Workspace): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    return this.doPost<CommitSummary>(`${this.basePath}/${workspace.id}/commit`, {commitMessage, files}, queryParameters, headers);
  }

  public add(files: string[], workspace: Workspace): Promise<void>;
  public add(files: string[], workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/add`, files, queryParameters, headers);
  }

  public remove(files: string[], workspace: Workspace): Promise<void>;
  public remove(files: string[], workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/remove`, files, queryParameters, headers);
  }

  public push(workspace: Workspace, force: boolean, newBranch?: boolean, branch?: string): Promise<PushResult>;
  public push(workspace: Workspace, force: boolean = false, newBranch?: boolean, branch?: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('force', String(force));
    if (newBranch && branch) {
      queryParameters = queryParameters.append('newBranch', newBranch.toString());
      queryParameters = queryParameters.append('branch', branch);
    }

    return this.doPost<PushResult>(`${this.basePath}/${workspace.id}/push`, {}, queryParameters, headers);
  }

  public pull(workspace: Workspace): Promise<PullResult>;
  public pull(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<PullResult>(`${this.basePath}/${workspace.id}/pull`, {}, queryParameters, headers);
  }

  public status(workspace: Workspace): Promise<StatusResult>;
  public status(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<StatusResult>(`${this.basePath}/${workspace.id}/status`, queryParameters, headers);
  }

  public fetch(workspace: Workspace): Promise<FetchResult>;
  public fetch(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<FetchResult>(`${this.basePath}/${workspace.id}/fetch`, {}, queryParameters, headers);
  }

  public merge(workspace: Workspace, from: string, to: string): Promise<void>;
  public merge(workspace: Workspace, from: string, to: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('from', from);
    queryParameters = queryParameters.append('to', to);

    return this.doPost<any>(`${this.basePath}/${workspace.id}/merge`, {}, queryParameters, headers);
  }

  public stash(workspace: Workspace, message: string, filesToStash: string[]): Promise<boolean> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    return this.doPost<boolean>(`${this.basePath}/${workspace.id}/stash/shelve`, {message, filesToStash}, queryParameters, headers);
  }

  public getStash(workspace: Workspace): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    return this.doGet<boolean>(`${this.basePath}/${workspace.id}/stash/`, queryParameters, headers);
  }

  public unstash(workspace: Workspace, hash?: string): Promise<void>;
  public unstash(workspace: Workspace, hash?: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    if (hash) {
      queryParameters = queryParameters.append('hash', hash);
    }

    return this.doPost<any>(`${this.basePath}/${workspace.id}/stash/unshelve`, {}, queryParameters, headers);
  }

  public stashList(workspace: Workspace): Promise<StashList>;
  public stashList(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<StashList>(`${this.basePath}/${workspace.id}/stash/list`, queryParameters, headers);
  }


  public readConflictedFile(path: string, workspace: Workspace): Promise<{ value: string }>;
  public readConflictedFile(path: string, workspace: Workspace): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('filePath', path);
    return this.doGet<any>(`${this.basePath}/${workspace.id}/readConflictedFile`, queryParameters, headers);
  }

  public saveResolvedFile(path: string, workspace: Workspace, fileContent: string): Promise<void>;
  public saveResolvedFile(path: string, workspace: Workspace, fileContent: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('filePath', path);
    return this.doPost<any>(`${this.basePath}/${workspace.id}/saveResolvedFile`, {fileContent}, queryParameters, headers);
  }

  public clearStash(workspace: Workspace): Promise<void>;
  public clearStash(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doPost<any>(`${this.basePath}/${workspace.id}/stash/clear`, {}, queryParameters, headers);
  }

  public dropStashEntry(workspace: Workspace, hash: string): Promise<void>;
  public dropStashEntry(workspace: Workspace, hash: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('hash', hash);

    return this.doPost<any>(`${this.basePath}/${workspace.id}/stash/drop`, {}, queryParameters, headers);
  }

  public revertCommit(workspace: Workspace, hash: string): Promise<void>;
  public revertCommit(workspace: Workspace, hash: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('hash', hash);

    return this.doPost<any>(`${this.basePath}/${workspace.id}/revert`, {}, queryParameters, headers);
  }

  public reset(workspace: Workspace, mode: ResetMode, commitHash?: string): Promise<void>;
  public reset(workspace: Workspace, mode: ResetMode, commitHash?: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('mode', mode);
    if (commitHash) {
      queryParameters = queryParameters.append('commitHash', commitHash);
    }

    return this.doPost<any>(`${this.basePath}/${workspace.id}/reset`, {}, queryParameters, headers);
  }

  public diff(workspace: Workspace, files?: string[]): Promise<DiffResult> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();

    return this.doPost<DiffResult>(`${this.basePath}/${workspace.id}/diff`, {files}, queryParameters, headers);
  }

  public log(workspace: Workspace): Promise<StashList>;
  public log(workspace: Workspace): Promise<any> {
    const {queryParameters, headers} = this.getHeaders();

    return this.doGet<StashList>(`${this.basePath}/${workspace.id}/log`, queryParameters, headers);
  }

  public getLocalBranches(workspace: Workspace, name: string): Promise<BranchSummary>;
  public getLocalBranches(workspace: Workspace, name: string): Promise<any> {
    // eslint-disable-next-line prefer-const
    let {queryParameters, headers} = this.getHeaders();
    queryParameters = queryParameters.append('name', name);

    return this.doGet<any>(`${this.basePath}/${workspace.id}/branches/local`, queryParameters, headers);
  }
}
