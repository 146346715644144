<ng-template #builderContent let-breadcrumb>
  <ng-container *ngIf="multiple">
    <ui-query-builder (onExecute)="onExecute.emit($event)" (onUpdate)="forceUpdate()" *ngFor="let query of value; let index = index"
                      [(valueBinding)]="value[index]" [breadcrumb]="breadcrumb" [codeEditorOptions]="codeEditorOptions" [scriptLanguage]="scriptLanguage"
                      [dialogDisplay]="dialogDisplay" [allowScriptedRule]="true" [dialog]="false" [entityName]="entityMetas[index].name"
                      [formId]="formId" [isMultipleChild]="true"></ui-query-builder>
  </ng-container>
  <ng-container *ngIf="!multiple">
    <ng-container>
      <ng-container *ngTemplateOutlet="singleMode; context: {$implicit: breadcrumb}"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #singleMode let-breadcrumb>
  <ng-container *ngIf="showQuery">
    <label *ngIf="isMultipleChild && (!breadcrumb || (group.nonEmptyConditions?.length || group.nonEmptyGroups?.length))" class="one-label">
      {{BundleKeyGenerator.entityToKey(entityMeta) | translate}}</label>
    <dl #group (onExecute)="onExecute.emit()" (onUpdate)="forceUpdate()" [(valueBinding)]="value" [breadcrumb]="breadcrumb" [disable]="disable"
        [ngClass]="{'one-filter-nav__item-group one-filter-nav__item-group--no-style': breadcrumb, 'one-conditionbuilder__content InitGroup': !breadcrumb}"
        [draggable]="!disable" ui-query-builder-group></dl>
  </ng-container>
  <ng-container *ngIf="showOrder">
    <label *ngIf="showQuery && (!breadcrumb || sort_group.nonEmptyOrders?.length)" class="one-label">
      {{'QueryBuilder.Sort.Value' | translate}}
    </label>
    <dl #sort_group (onExecute)="onExecute.emit()" (onUpdate)="forceUpdate()" [(valueBinding)]="value" [breadcrumb]="breadcrumb" [disable]="disable"
        [ngClass]="{'one-filter-nav__item-group one-filter-nav__item-group--no-style': breadcrumb, 'one-conditionbuilder__content InitGroup': !breadcrumb}"
        ui-query-builder-sort-group></dl>
  </ng-container>
</ng-template>

<ng-container *ngIf="!dialog && loaded && !breadcrumbOnly && (dialogDisplay || !isMultipleChild)">
  <div class="one-conditionbuilder">
    <ng-container *ngTemplateOutlet="builderContent; context: {$implicit: false}"></ng-container>
  </div>
</ng-container>

<ui-dialog *ngIf="dialog && loaded" [(visible)]="dialogDisplay" [dialogFooter]="true" header="{{'QueryBuilder.Header' | translate}}"
           name="{{formId}}QueryBuilder">
  <ng-template #content>
    <ng-container *ngTemplateOutlet="builderContent"></ng-container>
  </ng-template>

  <ng-template #footer>
    <ui-button (clicked)="cancel();" [disable]="disable" [spinner]="false" icon="fas fa-fw fa-times" label="{{'QueryBuilder.Cancel' | translate}}"
               styleClass="queryCancelButton close_btn" type="cancel"></ui-button>
    <ui-button (clicked)="clear();" [disable]="disable" [spinner]="false" icon="fas fa-fw fa-eraser" label="{{'QueryBuilder.Clear' | translate}}"
               type="cancel"></ui-button>
    <ui-button (clicked)="run();" [disable]="disable" [spinner]="false" icon="fas fa-fw fa-check" label="{{'QueryBuilder.Run' | translate}}"
               type="primary"></ui-button>
  </ng-template>
</ui-dialog>

<ng-container *ngIf="breadcrumb">
  <ng-container *ngIf="!isMultipleChild">
    <div [ngClass]="{'one-filter-nav--multiple': multiple}" class="one-filter-nav one-helper-hidden--phone">
      <ng-container *ngTemplateOutlet="builderContent; context: {$implicit: true}"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="isMultipleChild">
    <ng-container *ngTemplateOutlet="builderContent; context: {$implicit: true}"></ng-container>
  </ng-container>
</ng-container>