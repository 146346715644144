<footer class="one-footer">
  <div class="one-footer__logo"></div>

  <p class="one-footer__phrase">
    Enterprise Service Management <strong>made in Germany </strong> — <strong>hosted in Germany</strong>
  </p>

  <p class="one-footer__links">
    <a href="https://wspsoft.de" target="_blank">wspsoft.de</a>
    |
    <a href="https://wspsoft.de/#kontakt" target="_blank">Kontakt</a>
  </p>
</footer>