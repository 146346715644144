import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BundleKeyGenerator, Constants, EntityModel, PrefQuery, Visibility} from '@wspsoft/frontend-backend-common';
import {CircularService, CriteriaFactory, EntityServiceFactory} from '../../../api';
import {PrefQueryEntity} from '../entities/pref-query.entity';


@Injectable()
export class ShareableEntityService {
  public constructor(private circularService: CircularService, private entityServiceFactory: EntityServiceFactory,
                     private criteriaFactoryService: CriteriaFactory, private translate: TranslateService) {
  }

  /**
   * Returns the share-permission that is necessary to view a specific dashboard.
   * E.g.: For a dashboard or prefQuery with the visibility 'private' the necessary share-permission would be 'SHARE_PRIVATE'
   * @param visibility the visibility of the dashboard/prefQuery etc.
   * @return the according share-permission
   */
  public getSharePermissionStringBasedOnShareVisibility(visibility: Visibility): string {
    switch (visibility) {
      case Visibility.PRIVATE:
        return Constants.SHARE_PRIVATE;
      case Visibility.PUBLIC:
        return Constants.SHARE_PUBLIC;
      default:
        return Constants.SHARE_USERGROUP;
    }
  }

  /**
   * Iterates over and checks for the share-permissions and returns the first visibility that can be used with this permission.
   * This function is used to set a default value for a dashboard when it is copied.
   * @param entity the name of the entity to check the Permissions for
   * @return Visibility the visibility that can be used with the first allowed permission
   */
  public getFirstAllowedShareVisibility(entity: string = 'Dashboard'): Visibility {
    let canUserDoThis;

    for (const operation of Constants.DEFAULT_SHARE_OPERATIONS) {
      canUserDoThis = this.circularService.sessionService.currentUser.can(operation, entity);
      if (canUserDoThis) {
        switch (operation) {
          case Constants.SHARE_PRIVATE:
            return Visibility.PRIVATE;
          case Constants.SHARE_PUBLIC:
            return Visibility.PUBLIC;
          case Constants.SHARE_USERGROUP:
            return Visibility.USER_GROUPS;
        }
      }
    }
  }


  /**
   * Creates a new empty PrefQuery for the current entity and sets its default values
   * @returns the new PrefQuery
   */
  public async getEmptyPrefQuery(entityMeta: EntityModel, scopeId: string, multiple?: boolean): Promise<PrefQueryEntity> {
    const prefQueryService = this.entityServiceFactory.getService<PrefQuery>('PrefQuery');
    const currentPrefQuery = await prefQueryService.getNewEntity('PrefQuery');
    this.setDefaultValues(currentPrefQuery, entityMeta, scopeId, multiple);
    // make sure to refresh record old so dirty === false
    this.setDefaultValues(currentPrefQuery.recordOld, entityMeta, scopeId, multiple);

    return new PrefQueryEntity(currentPrefQuery);
  }

  public userCanEdit(entityName: string, ownerId: string, visibility: Visibility): boolean {
    return this.circularService.sessionService.currentUser.can(
      this.getSharePermissionStringBasedOnShareVisibility(visibility),
      entityName) || (ownerId === this.circularService.sessionService.currentUser.id);
  }

  /**
   * Sets the default values of a PrefQuery
   */
  private setDefaultValues(currentPrefQuery: PrefQuery, entityMeta: EntityModel, scopeId: string, multiple?: boolean): void {
    const query = this.criteriaFactoryService.get(entityMeta.name);

    currentPrefQuery.payload = multiple ? [query.getJson()] : query.getJson();
    currentPrefQuery.scope = scopeId;
    currentPrefQuery.name = {[this.translate.currentLang]: this.translate.instant(BundleKeyGenerator.entityToKey(entityMeta))};
  }
}
