import {KolibriEntity} from './kolibri-entity';
import {ThirdPartyCredential} from './third-party-credential';

export enum SendAsType {
  EMAIL_ACCOUNT = 'emailAccount', USER = 'user'
}

export enum SendAsHeader {
  FROM = 'from', SENDER = 'sender'
}

export enum AuthenticationMethod {
  basic = 'basic',
  oauth = 'oauth'
}

export class EmailAccount extends KolibriEntity {
  public deleteAfterProcessing?: boolean;
  public entityName?: string;
  public host?: string;
  public lastFetchDate?: string;
  public name?: string;
  public password?: string;
  public port?: number;
  public processOnlyUnseen?: boolean;
  public protocol?: string;
  public userAccount?: string;
  public username?: string;
  public useSSL?: boolean;
  public sendAs?: SendAsType;
  public sendAsHeader?: SendAsHeader;
  public microsoftProvider?: ThirdPartyCredential;
  public oauthCredentials?: ThirdPartyCredential;
  public authMethod?: AuthenticationMethod;
}
