import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {NotificationRule} from './notification-rule';
import {ReportRuleContent} from './report-rule-content';

export abstract class ReportRule extends NotificationRule {
  public recipients?: string[];
  public queryCondition?: CriteriaQueryJson | string;
  public reportContents?: ReportRuleContent;
  public reportContentsId?: string;
  public repeatable?: boolean;
  public reportCronDescription?: string;
  public sendAtDate?: string;
  public minute?: string;
  public second?: string;
  public dayOfWeek?: string;
  public hour?: string;
  public month?: string;
  public dayOfMonth?: string;
}
