<div (focusin)="hasFocus = true" (focusout)="hasFocus = false" (mouseenter)="hovered = true" (mouseleave)="hovered = false" [ngClass]="{
'one-input__wrapper': true,
'p-inline-label': hasInlineLabel,
'p-float-label': !hasInlineLabel,
'p-centered-label': hasCenteredLabel,
'one-input__group': renderInputGroup,
'one-input__group--reverse': hasReversedLabelPosition,
'p-state-filled': filled || hasLabelAlwaysUp,
'p-disabled': disable
}" class="{{showMessage && severity ? 'p-state-' + severity : ''}}">

  <label [ngClass]="ngClassLabel" (click)="doLabelClick($event)" *ngIf="label">{{ label }}{{ require && !disable ? ' *' : '' }}
    <ui-button *ngIf="isSpecialFormat && hovered && !disable" (clicked)="doLabelButtonClick($event)" [type]="['inline']" class="one-input__linkify-button"
               icon="fas fa-fw fa-pen" label="{{'Input.ToggleFormattedDisplay' | translate}}"></ui-button>
  </label>

  <ng-container *ngIf="isSpecialFormat && !editMode">
    <div [innerHTML]="linkifyValue | safe: 'html'" [ngClass]="{'one-input--overflow': linkifyRows>1}" class="one-input" style="--rows: {{linkifyRows}}"></div>
  </ng-container>

  <ng-container *ngIf="!isSpecialFormat || editMode">
    <ng-content></ng-content>
  </ng-container>

  <div class="one-input__message" [ngClass]="{
  'one-input__message--min-height': renderInputGroup,
  }">
    <p-message *ngIf="showMessage" [severity]="severity" [text]="message"></p-message>
  </div>
</div>
