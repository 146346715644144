import {_} from '@wspsoft/underscore';
import {ContentSecurityPolicy} from '../../model/database/content-security-policy';
import {NpmDependency} from '../../model/database/npm-dependency';
import {Field} from '../../model/response/field';
import {FieldResponse} from '../../model/response/field-response';
import {Application} from '../../model/xml/application';
import {Entity} from '../../model/xml/entity';
import {
  Attribute,
  Button,
  Choice,
  ChoiceValue,
  DisplayTransformation,
  FullCalendarEvent,
  FullCalendarResource,
  GuidedTour,
  GuidedTourStep,
  Layout,
  Relation,
  Type,
  View,
  VirtualCollection,
  Wizard,
  WizardSection
} from '../../model/xml/models';
import {Script, ScriptType} from '../../model/xml/script';
import {BundleKeyGenerator} from '../../util/bundle-key-generator';
import {Utility} from '../../util/utility';
import {ApplicationModel} from '../entities/application-model';
import {EntityModel} from '../entities/entity-model';
import {FirstLevelEntityEnhancer} from '../util/first-level-entity-enhancer';
import {ModelDotWalker} from '../util/model-dot-walker';
import {AbstractModelService} from './abstract-model.service';

export abstract class AbstractRuntimeModelService implements AbstractModelService {
  public static readonly CHOICE: string = 'Choice';
  public static readonly KOLIBRI_ENTITY: string = 'KolibriEntity';
  public static readonly KOLIBRI_ENTITY_ARRAY: string = 'KolibriEntity[]';
  public static readonly I18N_TYPE: string = '3bd3ea2e-7cc6-461e-93f1-748c3f2d0354';
  public static readonly DATE_TYPE: string = '38a7d898-da61-4b1f-a8b0-65a9bb73d36b';
  public static readonly LARGE_TEXT_TYPE: string = '961c7a5e-09ec-4ebc-b9f4-085446153260';
  public static readonly LARGE_JSON_TYPE: string = '424416c0-16bf-403a-89fd-696f5b25b705';
  public static readonly LARGE_BINARY_TYPE: string = '41e6fff4-f321-4caaA-931b-8c4ed8cc2fe1';
  public static readonly VARIABLES_DATA_TYPE: string = '535f99a8-3b06-429b-a267-a421f1ee204d';

  public applications: { [idOrName: string]: ApplicationModel } = {};
  public applicationsById: ApplicationModel[] = [];

  protected buttons: { [id: string]: Button } = {};

  protected contentSecurityPolicies: { [idOrName: string]: ContentSecurityPolicy } = {};
  protected npmDependencies: { [idOrName: string]: NpmDependency } = {};
  protected virtualCollections: { [idOrName: string]: VirtualCollection } = {};
  protected views: { [idOrName: string]: View } = {};

  private types: { [idOrName: string]: Type } = {};
  private typesById: Type[] = [];

  private displayTransformations: { [idOrName: string]: DisplayTransformation } = {};
  private displayTransformationsById: DisplayTransformation[] = [];

  private guidedTours: { [idOrStartPageLayoutId: string]: GuidedTour } = {};
  private guidedToursById: GuidedTour[] = [];

  private guidedTourSteps: { [idOrName: string]: GuidedTourStep } = {};
  private guidedTourStepsById: GuidedTourStep[] = [];

  private wizards: { [idOrName: string]: Wizard } = {};
  private wizardSections: { [idOrName: string]: WizardSection } = {};
  private choices: { [idOrName: string]: Choice } = {};

  private entities: { [idOrName: string]: EntityModel } = {};
  private entitiesById: EntityModel[] = [];

  private layouts: { [idOrName: string]: Layout } = {};
  private layoutsById: Layout[] = [];

  private fullCalendarResources: { [idOrName: string]: FullCalendarResource } = {};
  private fullCalendarResourcesById: FullCalendarResource[] = [];

  private fullCalendarEvents: { [idOrName: string]: FullCalendarEvent } = {};
  private fullCalendarEventsById: FullCalendarEvent[] = [];

  private fields: { [idOrName: string]: { [idOrName: string]: { field: Field; entity: EntityModel; own: boolean } } } = {};

  public reset(): void {
    this.applications = {};
    this.applicationsById = [];

    this.types = {};
    this.typesById = [];

    this.wizards = {};
    this.wizardSections = {};
    this.choices = {};

    this.entities = {};
    this.entitiesById = [];

    this.fields = {};
    this.contentSecurityPolicies = {};
    this.npmDependencies = {};
    this.virtualCollections = {};
    this.views = {};

    this.guidedTours = {};
    this.guidedToursById = [];

    this.guidedTourSteps = {};
    this.guidedTourStepsById = [];

    this.displayTransformations = {};
    this.displayTransformationsById = [];

    this.fullCalendarEvents = {};
    this.fullCalendarEventsById = [];

    this.fullCalendarResources = {};
    this.fullCalendarResourcesById = [];

    this.buttons = {};
  }

  public abstract updateApplication(modelXml: any): Application;

  public abstract getClientData(): Promise<ApplicationModel[]>;

  public abstract getEntitiesLocalized(name?: string): Promise<Entity[]>;

  public abstract getFieldsLocalized(entity: string, name?: string): Promise<FieldResponse>;

  public async init(): Promise<void> {
    // @ts-ignore because we are going to initialize it now
    this.applications = await this.getClientData();
    this.cache(true);
  }

  public cache(apps?: boolean): void {
    BundleKeyGenerator.modelService = this;
    const applications = _.uniqBy(Object.values(this.applications), 'id');
    if (apps) {
      this.applications = {..._.zipObject(applications.map(t => t.id), applications), ..._.zipObject(applications.map(t => t.name), applications)};
      this.applicationsById = applications;
    }

    const entities: EntityModel[] = [];
    const enhancer = new FirstLevelEntityEnhancer();
    for (const application of applications) {
      for (const key of Object.keys(application.entities)) {
        application.entities[key] = enhancer.designerEnhancement(this, application.entities[key]);
        entities.push(application.entities[key]);
      }
    }
    this.entities = {..._.zipObject(entities.map(t => t.id), entities), ..._.zipObject(entities.map(t => t.name), entities)};
    this.entitiesById = _.uniqBy(Object.values(this.entities), 'id');

    const types = _.flatMap(applications, 'types');
    this.types = {..._.zipObject(types.map(t => t.id), types), ..._.zipObject(types.map(t => t.name), types)};
    this.typesById = _.uniqBy(Object.values(this.types), 'id');

    const displayTransformations = _.flatMap(applications, 'displayTransformations');
    this.displayTransformations = {
      ..._.zipObject(displayTransformations.map(t => t.id), displayTransformations), ..._.zipObject(displayTransformations.map(t => t.name),
        displayTransformations)
    };
    this.displayTransformationsById = _.uniqBy(Object.values(this.displayTransformations), 'id');

    const guidedTours = _.flatMap(applications, 'guidedTours');
    this.guidedTours = {..._.zipObject(guidedTours.map(t => t.id), guidedTours), ..._.zipObject(guidedTours.map(t => t.name), guidedTours)};
    this.guidedToursById = _.uniqBy(Object.values(this.guidedTours), 'id');

    const guidedTourSteps = _.flatMap(applications, 'guidedTourSteps');
    this.guidedTourSteps = {
      ..._.zipObject(guidedTourSteps.map(t => t.id), guidedTourSteps), ..._.zipObject(guidedTourSteps.map(t => t.name), guidedTourSteps)
    };
    this.guidedTourStepsById = _.uniqBy(Object.values(this.guidedTourSteps), 'id');

    const wizards = _.flatMap(applications, 'wizards');
    this.wizards = {..._.zipObject(wizards.map(t => t.id), wizards), ..._.zipObject(wizards.map(t => t.name), wizards)};

    const wizardSections = _.flatMap(applications, 'wizardSections');
    this.wizardSections = {..._.zipObject(wizardSections.map(t => t.id), wizardSections), ..._.zipObject(wizardSections.map(t => t.name), wizardSections)};

    const choices = types.filter((x) => (x as Choice).values) as Choice[];
    this.choices = {..._.zipObject(choices.map(t => t.id), choices), ..._.zipObject(choices.map(t => t.name), choices)};

    const layouts = _.flatMap(entities, 'layouts');
    this.layouts = {
      ..._.zipObject(layouts.map(t => t.id), layouts),
      ..._.zipObject(layouts.map(t => t.name), layouts),
      ..._.zipObject(layouts.map(t => t.url), layouts)
    };
    this.layoutsById = _.uniqBy(Object.values(this.layouts), 'id');

    const events = _.flatMap(entities, 'fullCalendarEvents');
    this.fullCalendarEvents = {
      ..._.zipObject(events.map(t => t.id), events)
    };
    this.fullCalendarEventsById = _.uniqBy(Object.values(this.fullCalendarEvents), 'id');

    const resources = _.flatMap(entities, 'fullCalendarResources');
    this.fullCalendarResources = {
      ..._.zipObject(resources.map(t => t.id), resources)
    };
    this.fullCalendarResourcesById = _.uniqBy(Object.values(this.fullCalendarResources), 'id');

    const buttons = _.flatMap(this.getApplications(), 'buttons');
    this.buttons = {
      ..._.zipObject(buttons.map(t => t.id), buttons)
    };

    for (const entity of entities) {
      this.fields[entity.id] = this.fields[entity.name] = {};
      const fields = _.union<Field>(entity.allAttributes, entity.allRelations);
      for (const field of fields) {
        this.fields[entity.id][field.id] = this.fields[entity.id][field.name] =
          this.fields[entity.name][field.id] = this.fields[entity.name][field.name] = {
            field, entity, own: field.entityId === entity.id
          };
      }
    }
  }

  public getApplications(idOrName?: string): ApplicationModel[] {
    if (idOrName) {
      return [this.applications[idOrName]];
    }

    return this.applicationsById;
  }

  public getApplication(idOrName: string): ApplicationModel {
    return this.applications[idOrName];
  }

  public getTypes(idOrName?: string): Type[] {
    if (idOrName) {
      return [this.types[idOrName]];
    }

    return this.typesById;
  }

  public getType(idOrName: string): Type {
    return this.types[idOrName];
  }

  public getDisplayTransformations(idOrName?: string): DisplayTransformation[] {
    if (idOrName) {
      return [this.displayTransformations[idOrName]];
    }
    return this.displayTransformationsById;
  }

  public getDisplayTransformation(idOrName: string): DisplayTransformation {
    return this.displayTransformations[idOrName];
  }

  public getGuidedTours(idOrName?: string): GuidedTour[] {
    if (idOrName) {
      return [this.guidedTours[idOrName]];
    }
    return this.guidedToursById;
  }

  public getGuidedTour(idOrName: string): GuidedTour {
    return this.guidedTours[idOrName];
  }

  public getGuidedTourSteps(idOrName?: string): GuidedTourStep[] {
    if (idOrName) {
      return [this.guidedTourSteps[idOrName]];
    }
    return this.guidedTourStepsById;
  }

  public getGuidedTourStep(idOrName: string): GuidedTourStep {
    return this.guidedTourSteps[idOrName];
  }

  public getFullCalendarResource(idOrName: string): FullCalendarResource {
    return this.fullCalendarResources[idOrName];
  }

  public getFullCalendarResources(idOrName?: string): FullCalendarResource[] {
    if (idOrName) {
      return [this.fullCalendarResources[idOrName]];
    }

    return this.fullCalendarResourcesById;
  }

  public getFullCalendarEvent(idOrName: string): FullCalendarEvent {
    return this.fullCalendarEvents[idOrName];
  }

  public getFullCalendarEvents(idOrName?: string): FullCalendarEvent[] {
    if (idOrName) {
      return [this.fullCalendarEvents[idOrName]];
    }

    return this.fullCalendarEventsById;
  }

  public getWizard(idOrName: string): Wizard {
    return this.wizards[idOrName];
  }

  public getWizardSection(idOrName: string): WizardSection {
    return this.wizardSections[idOrName];
  }

  public getVirtualCollection(idOrName: string): Wizard {
    return this.virtualCollections[idOrName];
  }

  public getView(idOrName: string): Wizard {
    return this.views[idOrName];
  }

  public getTypeName(field: Field): string {
    if (!field) {
      return 'String';
    }

    if (Utility.isAttribute(field)) {
      const type = this.getFieldType(field);
      if (type.entityClass === AbstractRuntimeModelService.CHOICE) {
        return type.entityClass;
      } else {
        // return java name for Boolean, Date, etc.
        return type.name;
      }
    } else {
      const relation = field as Relation;
      if (Utility.isToManyRelation(relation)) {
        return AbstractRuntimeModelService.KOLIBRI_ENTITY_ARRAY;
      }
      // relations
      return AbstractRuntimeModelService.KOLIBRI_ENTITY;
    }
  }

  public getChoice(idOrName: string): Choice {
    return this.choices[idOrName];
  }

  public getChoiceValue(choiceNameOrId: string, choiceValue: string): ChoiceValue {
    for (const value of this.getChoice(choiceNameOrId).values || []) {
      if (value.value === choiceValue) {
        return value;
      }
    }
  }

  public getEntities(idOrName?: string): EntityModel[] {
    if (idOrName) {
      return [this.entities[idOrName]];
    }

    return this.entitiesById;
  }

  public getEntity(idOrName: string): EntityModel {
    return this.entities[idOrName];
  }

  public getEntityByType(relation: Relation): EntityModel {
    if (!relation.targetId) {
      return;
    }

    return this.getEntity(relation.targetId);
  }

  public getScriptTriggers(entityIdOrName: string): Script[] {
    if (entityIdOrName === null || entityIdOrName === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getScriptTriggers.');
    }

    return this.getEntity(entityIdOrName).allScripts;
  }

  public getLayout(idOrUrl: string): Layout {
    return this.layouts[idOrUrl];
  }

  public getLayouts(): Layout[] {
    return this.layoutsById;
  }

  public getButton(id: string): Button {
    return this.buttons[id];
  }

  public getRelations(entity: string): Relation[] {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getRelations.');
    }

    return this.getEntity(entity).allRelations;
  }

  public getRelation(entityIdOrName: string, fieldIdOrName: string): Relation {
    if (entityIdOrName === null || entityIdOrName === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getRelation.');
    }

    return this.getFieldInfo(entityIdOrName, fieldIdOrName)?.field;
  }

  public getAttributes(entity: string): Attribute[] {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getAttributes.');
    }

    return this.getEntity(entity).allAttributes;
  }

  public getFields(entity: string, name?: string, substring?: boolean): FieldResponse {
    let entityModel = this.getEntity(entity);
    const modelDotWalker = new ModelDotWalker(name, entityModel, this, false, substring).invoke();

    // overwrite initial params with dot walks
    entityModel = modelDotWalker.entity;
    name = modelDotWalker.finalName;

    return {
      fields: _.union<Field>(entityModel.allAttributes, entityModel.allRelations).filter(value => Utility.matches(value.name, name, !substring)),
      entity: modelDotWalker.entity,
      path: modelDotWalker.path,
    };
  }

  public getFieldInfo(entityIdOrName: string, fieldIdOrName: string): { field: Field; entity: EntityModel; own: boolean } {
    if (!fieldIdOrName) {
      return;
    }
    // in case someone searches for the id relation field
    const fields = this.fields[entityIdOrName];
    if (fields) {
      return fields[fieldIdOrName] || fields[Utility.unparameterizeEntityName(fieldIdOrName)];
    }
  }

  public getField(entity: string, nameWithDotWalk: string): Field {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling getField.');
    }

    if (nameWithDotWalk === null || nameWithDotWalk === undefined) {
      throw new Error('Required parameter nameWithDotWalk was null or undefined when calling getField.');
    }

    if (!nameWithDotWalk.includes('.')) {
      return this.getFieldInfo(entity, nameWithDotWalk)?.field;
    }

    const fieldResponse = this.getFields(entity, nameWithDotWalk);
    return fieldResponse.fields.find((x) => x.name === Utility.getDotWalkTarget(nameWithDotWalk));
  }

  public iterateObject(hostEntityMeta: Entity, currentPath: string, path: string[],
                       fn: (field: Field, entityMeta: Entity, last: boolean, column?: string) => void): void {
    const column = path.shift();
    currentPath += (currentPath ? '.' : '') + column;
    const fieldResponse = this.getFields(hostEntityMeta.id, currentPath);
    const field = fieldResponse.fields.find((x) => Utility.matches(x.name, Utility.getDotWalkTarget(currentPath), true));

    fn(field, fieldResponse.entity, path.length === 0, column);

    if (path.length && field) {
      this.iterateObject(hostEntityMeta, currentPath, path, fn);
    }
  }

  public findDescendants(entity: EntityModel): EntityModel[] {
    const result = [];
    for (const entityModel of this.getEntities()) {
      if (entityModel.isDescendantOf(entity)) {
        result.push(entityModel);
      }
    }
    return result;
  }

  public getGlobalScripts(type?: ScriptType): Script[] {
    return _.flatMap(this.getApplications(), 'globalScripts')
      .filter(o => (o.type === type || o.type === ScriptType.BOTH));
  }

  public getFieldType(field: Field): Type {
    return this.getType((field as Attribute).typeId);
  }
}
