import {ChoiceValue} from '../xml/choice-value';
import {UserSessionSettings} from './json/user-session.settings';
import {KolibriEntity} from './kolibri-entity';
import {Picklist} from './picklist';
import {User} from './user';

export class UserProfile extends KolibriEntity {
  public name?: string;
  public timezone?: string;
  public sessionSettings?: UserSessionSettings;
  public guiLanguage?: string;
  public themeId?: string;
  public notificationLanguage?: string;
  public user?: User;
  public userId?: string;
  public fontSize?: ChoiceValue;
  public viewMode?: ChoiceValue;
  public viewTenants?: string[];

  public rowsPerPage?: number;
}
