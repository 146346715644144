import {LayoutListToListColumn} from './layout-list-to-list-column';
import {ListLayout} from './list-layout';


export abstract class LayoutList extends ListLayout {
  public exportToXml?: boolean;
  public columnToggler?: boolean;
  public resizableColumns?: boolean;
  public exportToJson?: boolean;
  public exportToPdf?: boolean;
  public exportToExcel?: boolean;
  public exportToCsv?: boolean;
  public layoutListToListColumns?: LayoutListToListColumn[];
}

