import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CriteriaConditionJson, CriteriaQueryGroupJson, Utility} from '@wspsoft/frontend-backend-common';
import {_} from '@wspsoft/underscore';
import {MenuItem} from 'primeng/api';
import {ToggleItem} from '../../../../entities/toggle-item';

import {QueryBuilderService} from '../../../../service/query-builder.service';
import {CustomWriter} from '../../custom-writer';
import {QueryBuilderDataService} from '../query-builder/query-builder-data.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[ui-query-builder-group]',
  templateUrl: './query-builder-group.component.html',
  styleUrls: ['./query-builder-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderGroupComponent extends CustomWriter<CriteriaQueryGroupJson> implements OnInit, OnChanges {
  @Input()
  public draggable: boolean;
  @Input()
  public parent: QueryBuilderGroupComponent;
  @Input()
  public breadcrumb: boolean;
  @Input()
  public disable: boolean;
  @Output()
  public onExecute: EventEmitter<void> = new EventEmitter<void>();
  public breadcrumbMenu: MenuItem[];
  public nonEmptyGroups: CriteriaQueryGroupJson[];
  public nonEmptyConditions: CriteriaConditionJson[];

  @ViewChildren(QueryBuilderGroupComponent)
  private groups: QueryList<QueryBuilderGroupComponent>;

  public constructor(private queryBuilderService: QueryBuilderService, private translateService: TranslateService,
                     public queryBuilderDataService: QueryBuilderDataService,
                     cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public get id(): string {
    return `${this.value.id.replace(/-/g, '_')}_group`;
  }


  public ngOnInit(): void {
    this.ngOnChanges();

    this.breadcrumbMenu = [
      new ToggleItem(this.value, this.translateService, () => {
        this.value.active = !this.value.active;
        this.forceUpdate();
        this.onExecute.emit();
      }),
      {
        label: this.translateService.instant('QueryBuilder.Group.RemoveGroup'),
        icon: 'fas fa-fw fa-trash-alt',
        styleClass: 'p-menuitem--negative',
        command: () => {
          this.removeGroup();
          this.onExecute.emit();
        }
      }];
  }

  public ngOnChanges(): void {
    if (!this.value.whereCondition.length) {
      this.addRule();
    }
  }

  public addRule(): void {
    const criteriaConditionJson = new CriteriaConditionJson();
    this.value.whereCondition.push(criteriaConditionJson);
  }

  public addGroup(): void {
    const criteriaQueryGroupJson = new CriteriaQueryGroupJson();
    criteriaQueryGroupJson.useOr = true;
    this.value.groups.push(criteriaQueryGroupJson);
  }

  public removeGroup(): void {
    _.remove(this.parent.value.groups, group1 => group1.id === this.value.id);
    this.forceUpdate();
  }

  public moveGroup(value?: CriteriaQueryGroupJson): void {
    this.queryBuilderService.draggedQueryElement = value;
    this.queryBuilderService.onDrop = () => {
      this.removeGroup();
    };
  }

  public moveElement(target: Event, index: number): void {
    target.stopPropagation();
    this.queryBuilderService.onDrop();
    const element = this.queryBuilderService.draggedQueryElement;
    if (('whereCondition' in element)) {
      this.value.groups.splice(index, 0, element);
    } else {
      this.value.whereCondition.splice(index, 0, element as CriteriaConditionJson);
    }
    this.forceUpdate();
  }

  /**
   * Returns all non empty conditions by checking if the column name, operator and value are set
   * @returns the filtered CriteriaConditionJson
   */
  public getNonEmptyConditions(): CriteriaConditionJson[] {
    // push result to local array -> otherwise this function needs to be called multiple times inside the html
    this.nonEmptyConditions = this.value.whereCondition.filter(
      whereCondition => whereCondition.columnName && whereCondition.operator && (Utility.isOperatorOnly(
        whereCondition.operator) || whereCondition.value !== undefined));
    return this.nonEmptyConditions;
  }

  /**
   * Returns all non empty groups by checking if the groups have subgroups or the where conditions are correctly filled
   * @returns the filtered CriteriaConditionJson
   */
  public getNonEmptyGroups(): CriteriaQueryGroupJson[] {
    // push result to local array -> otherwise this function needs to be called multiple times inside the html
    this.nonEmptyGroups = this.value.groups.filter(group => group.groups?.length || group.whereCondition.filter(
      whereCondition => whereCondition.columnName && whereCondition.operator && whereCondition.value !== undefined).length);
    return this.nonEmptyGroups;
  }
}
