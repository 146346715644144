import {CriteriaQueryJson} from '../../criteria/json/criteria-query-json';
import {EmailAccount} from './email-account';
import {KolibriEntity} from './kolibri-entity';
import {MessageTemplate} from './message-template';
import {NotificationRule} from './notification-rule';

export enum NotificationRuleContentType {
  EMAIL = 'email',
  BELL = 'bell'
}

export abstract class NotificationRuleContent extends KolibriEntity {
  public condition?: CriteriaQueryJson | string;
  public recipientsScript?: string;
  public recipientsScripted?: boolean;
  public recipients?: string[];
  public recipientFields?: string[];
  public ccRecipientsScript?: string;
  public ccRecipientsScripted?: boolean;
  public ccRecipients?: string[];
  public ccRecipientFields?: string[];
  public type?: NotificationRuleContentType;
  public userOverride?: boolean;
  public account?: EmailAccount;
  public accountId?: string;
  public template?: MessageTemplate;
  public templateId?: string;
  public contentText?: string;
  public overrideCondition?: boolean;
  public overrideRecipients?: boolean;
  public rule?: NotificationRule;
  public ruleId?: string;
}
