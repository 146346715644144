<login-authentication-page (onEnter)="save()">
  <ng-template #header>
    <span class="one-login__greeting--big one-login__greeting--welcome">{{'UserProfile.ChangePassword.Greeting' | translate}} </span>
    <span *ngIf="!token || token.length === 10">{{'UserProfile.ChangePassword.Welcome' | translate}}</span>
  </ng-template>
  <ng-template #content>
    <form #form="ngForm" *ngIf="!token || token.length === 10 else tokenError" class="one-login__form" method="post">
      <div class="one-login__form-fields">
        <ng-container ngModelGroup="password">
          <ui-password-input [(ngModel)]="newPassword" [disableAutofill]="true" [require]="true"
                             [strongRegex]="'^(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W|_])(?=.*[0-9])(?=.{8,})'" helpMessage="Password.HtmlMessage"
                             label="{{'UserProfile.ChangePassword.NewPassword' | translate}}" name="password"></ui-password-input>
        </ng-container>
        <ng-container ngModelGroup="repeatPassword">
          <ui-password-input [(ngModel)]="newPasswordRep" [disableAutofill]="true" [require]="true"
                             [strongRegex]="'^(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W|_])(?=.*[0-9])(?=.{8,})'" helpMessage="Password.HtmlMessage"
                             label="{{'UserProfile.ChangePassword.RepeatNewPassword' | translate}}" name="repeatPassword"></ui-password-input>
        </ng-container>
      </div>
      <footer class="one-login__footer">
        <ui-button (clicked)="save($event.cb)" class="one-login__button--full-width" icon="fas fa-fw fa-sign-in-alt"
                   label="{{'UserProfile.ChangePassword.Submit' | translate}}" styleClass="one-login__button" type="primary"></ui-button>
      </footer>
    </form>
  </ng-template>
</login-authentication-page>

<ng-template #tokenError>
  {{'Login.PasswordReset.TokenError' | translate}}
</ng-template>
