import {array} from '@amcharts/amcharts5';
import {ChangeDetectorRef, Directive, EventEmitter, Input, Output} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {KolibriEntity} from '@wspsoft/frontend-backend-common';
import {_, MaybePromise} from '@wspsoft/underscore';
import {Converter, DefaultConverter} from '../converter/converter';

declare type FormHooks = 'change' | 'blur' | 'submit';

@Directive()
export abstract class CustomInput<E> implements ControlValueAccessor {
  public get rawValue(): string | string[] | { [p: string]: any } {
    return this.prawValue;
  }

  public set rawValue(value: string | string[] | { [p: string]: any }) {
    // We have to check if the previous value was null and the value that gets set is not null.
    // Then we can assume that it is the first load and can fire the initial Change Event to trigger the style transformation
    // Note: this may also fire if an empty value gets filled even after the initial loading is done
    if (_.isNullOrEmpty(this.prawValue) && !_.isNullOrEmpty(value)) {
      this.prawValue = value;
      this.initialChange.emit();
    } else {
      this.prawValue = value;
    }
  }

  @Input()
  public nativeElement: boolean;
  @Input()
  public styleData: { [p: string]: any };
  @Input()
  public require: boolean = false;
  @Input()
  public converter: Converter<any, any> = new DefaultConverter();
  @Output()
  public onChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public initialChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  public convertChange: EventEmitter<any> = new EventEmitter<any>();
  public editMode: boolean = false;
  private prawValue: string | string[] | { [key: string]: any } = null;
  protected originalValue: E = null;
  protected originalValueOld: E = null;
  protected changeListener: ((value: string | string[] | { [key: string]: any }) => void)[] = [];
  protected touchListener: ((value: string | string[] | { [key: string]: any }) => void)[] = [];
  protected pdisableSelf: boolean;

  public constructor(public cdr: ChangeDetectorRef) {
  }

  private pdisable: boolean;

  @Input()
  public get disable(): boolean {
    return this.pdisable || this.pdisableSelf || null;
  }

  public set disable(value: boolean) {
    this.pdisable = value;
  }

  protected pmultiple: boolean = false;

  @Input()
  public get multiple(): boolean {
    return this.pmultiple;
  }

  public set multiple(value: boolean) {
    // delete value if the new value for multiple different from the old one
    if (this.pmultiple !== value) {
      this.setValue(null);
    }
    this.pmultiple = value;
  }

  private pupdateWhileTyping: boolean;

  @Input()
  public get updateWhileTyping(): boolean {
    return this.pupdateWhileTyping;
  }

  public set updateWhileTyping(value: boolean) {
    this.pupdateWhileTyping = value;
  }

  private plabel: string;

  @Input()
  public get label(): string {
    return this.plabel;
  }

  public set label(value: string) {
    this.plabel = value;
  }

  private phelpMessage: string;

  @Input()
  public get helpMessage(): string {
    return this.phelpMessage;
  }

  public set helpMessage(value: string) {
    this.phelpMessage = value;
  }

  private plinkify: boolean = false;

  @Input()
  public get linkify(): boolean {
    return this.plinkify;
  }

  public set linkify(value: boolean) {
    this.plinkify = value;
  }

  /**
   * get the ng model option for update
   */
  public get updateOn(): FormHooks {
    return this.updateWhileTyping ? 'change' : 'blur';
  }

  @Input()
  public get value(): E {
    // always read the object value, but write converted one
    return this.originalValue;
  }

  public set value(value: E) {
    this.setValue(value);
  }

  public get oldValue(): E {
    return this.originalValueOld;
  }

  public touch(): void {
    this.touchListener.forEach(f => f(this.rawValue));
  }

  public registerOnChange(fn: (value: string | string[] | { [key: string]: any }) => void): void {
    this.changeListener.push(fn);
  }

  public registerOnTouched(fn: (value: string | string[] | { [key: string]: any }) => void): void {
    this.touchListener.push(fn);
  }

  public writeValue(value: E): void {
    this.setValue(value, false);
  }

  /**
   * check if given value needs to be converted
   */
  protected needsConversion(value: any): MaybePromise<boolean> {
    if (this.converter.hasNeedsConversion && this.converter.hasNeedsConversion() && this.converter.needsConversion) {
      return this.converter.needsConversion({newValue: value, data: {originalValue: this.originalValue, rawValue: this.rawValue}});
    }
    return typeof value === 'string' || (Array.isArray(value) && _.some(value, x => typeof x === 'string'));
  }

  protected setRawValue(value: string | string[] | { [key: string]: any }, emitChange: boolean = true): void {
    this.rawValue = value;
    if (emitChange) {
      this.changeListener.forEach(f => f(this.rawValue));
      this.onChange.emit(this.nativeElement ? this.rawValue : this.value);
      this.touch();
    }
    this.cdr.detectChanges();
  }

  protected setValue(value: E, emitChange: boolean = true): void {
    // remember last thing
    this.originalValueOld = this.originalValue;

    _.maybeAwait(this.needsConversion(value), needConv => {
      // maybe unsafe, what about string to string conversion?
      if (needConv) {
        // e.g. entity id was inserted
        // in this case we have to convert the string to an usable object
        if (!_.isEqual(value, this.rawValue) || value === this.originalValue) {
          // @ts-ignore
          _.maybeAwait(this.converter.getAsObject(value), x => {
            this.originalValue = x;
            this.convertChange.emit(this.originalValue);

            // the raw value is correct as it is
            // @ts-ignore
            this.setRawValue(value, emitChange);
          });
        }
      } else {
        // some object was selected and we have to serialize it for json
        this.originalValue = value;
        _.maybeAwait(this.converter.getAsString(value), x => {
          // new value undefined and old value null is still no change
          if (x !== this.rawValue) {
            this.setRawValue(x, emitChange);
          }
        });
      }
    });
  }
}
