import {EmailAccount} from './email-account';
import {MessageTemplate} from './message-template';
import {NotificationRuleContent} from './notification-rule-content';
import {ReportRule} from './report-rule';

export enum ReportRuleContentType {
  EMAIL_ONLY = 'emailOnly',
  MAIL_AND_ATTACHMENT = 'mailAndAttachment'
}

export abstract class ReportRuleContent extends NotificationRuleContent {
  public reportRuleType?: ReportRuleContentType;
  public account?: EmailAccount;
  public accountId?: string;
  public entity?: string;
  public emailTemplate?: MessageTemplate;
  public emailTemplateId?: string;
  public attachmentTemplate?: MessageTemplate;
  public attachmentTemplateId?: string;
  public reportRule?: ReportRule;
  public reportRuleId?: string;
}
